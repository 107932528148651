import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import Editions from 'src/assets/Editions';
import StickyCoin from 'src/assets/StickyCoin';
import { PieChartData } from 'src/components/shared/Charts/PieChart/PieChartTypes';
import PieChart from '../../../../../../shared/Charts/PieChart/PieChart';
import Flex from '../../../../../../shared/Flex';
import NumericTypography from '../../../../../../shared/NumericTypography';

type AvailableChartValues = 'amount' | 'quantity';

type Props = {
  chartData: (show: AvailableChartValues) => PieChartData[];
  headerTitle: string;
  showChangeOption?: boolean;
  legendClickCallback?: (e: any, chart?: any, options?: any) => void;
  dataPointSelectionCallback?: (e: any, chart?: any, options?: any) => void;
};
export function IndividualChart({
  headerTitle,
  chartData,
  showChangeOption = true,
  dataPointSelectionCallback,
  legendClickCallback,
}: Props) {
  const [valueToShow, setValueToShow] =
    useState<AvailableChartValues>('quantity');

  const salesChartData = chartData(valueToShow);
  return (
    <Card
      sx={{
        width: '100%',
      }}
    >
      <CardHeader
        title={headerTitle}
        action={
          showChangeOption ? (
            <ToggleButtonGroup
              size="small"
              exclusive
              aria-label="change visualization mode"
              onChange={(e, value) => {
                if (value) {
                  setValueToShow(value);
                }
              }}
              value={valueToShow}
            >
              <ToggleButton value="amount" aria-label="left aligned">
                <StickyCoin />
              </ToggleButton>
              <ToggleButton value="quantity" aria-label="centered">
                <Editions />
              </ToggleButton>
            </ToggleButtonGroup>
          ) : null
        }
      />

      <PieChart
        data={salesChartData}
        dataPointSelectionCallback={dataPointSelectionCallback}
        legendClickCallback={legendClickCallback}
      />
      <CardContent>
        <Stack divider={<Divider />}>
          {salesChartData.map((data) => (
            <Flex justifyContent="space-between" py={0.5} key={data.label}>
              <Typography>{data.label}</Typography>
              <NumericTypography>{data.value}</NumericTypography>
            </Flex>
          ))}
        </Stack>
      </CardContent>
    </Card>
  );
}
