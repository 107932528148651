// components

import {
  AppRegistration,
  CollectionsRounded,
  CurrencyExchange,
  LocalMallRounded,
  NoAccounts,
  PaymentsRounded,
  PublicRounded,
  RateReview,
  Receipt,
  Report,
  Settings,
  Shop,
  Storage,
} from '@mui/icons-material';
import Routes from 'src/routes/Routes';
import Iconify from 'src/template/components/Iconify';
import { NavListProps } from 'src/template/components/nav-section/type';
import SvgIconStyle from 'src/template/components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <SvgIconStyle
    src={`/assets/icons/navbar/${name}.svg`}
    sx={{ width: 1, height: 1 }}
  />
);

const ICONS = {
  user: <Iconify icon="fa-solid:users" />,
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  collections: <CollectionsRounded />,
  regions: <PublicRounded />,
  payments: <PaymentsRounded />,
  nft_packs: <LocalMallRounded />,
  pools: <CurrencyExchange />,
  dangerous_users_activities: <NoAccounts />,
  refunds: <Receipt />,
  old_transactions: <CurrencyExchange />,
  iap_transactions: <Shop />,
  reports: <Report />,
  feedbacks: <RateReview />,
  home_feeds: <AppRegistration />,
  configurations: <Settings />,
  DBConsistency: <Storage />,
  insights: <Iconify icon="mdi:talk" />,
  subscriptions: <Iconify icon="ri:hand-coin-fill" />,
  checks: <Iconify icon="mdi:eye-check" />,
  sales: <Iconify icon="icon-park-solid:sales-report" />,
  products: <Iconify icon="mdi:cart-arrow-down" />,
  old_screens: <Iconify icon="mdi:monitor-dashboard" />,
  game_center: <Iconify icon="ion:game-controller" />,
  community: <Iconify icon="material-symbols:chat" />,
  search: <Iconify icon="fa:search" />,
  channels: <Iconify icon="material-symbols-light:live-tv" />,
  submission: <Iconify icon="solar:file-send-bold" />,
  moderation: <Iconify icon="mdi:shield-check" />,
};

type NavConfig = {
  subheader: string;
  items: NavListProps[];
};
const navConfig: NavConfig[] = [
  {
    subheader: 'management',
    items: [
      {
        title: 'Checks',
        path: Routes.checks,
        icon: ICONS.checks,
      },
      {
        title: 'Users',
        path: '',
        icon: ICONS.user,
        children: [
          {
            title: 'Users list',
            path: Routes.users.list,
          },
          {
            title: 'Rankings',
            path: Routes.user_rankings,
          },
          {
            title: 'Devices',
            path: Routes.devices,
          },
          {
            title: 'User Management Events',
            path: Routes.users.management_events,
          },
          {
            title: 'User Notes',
            path: Routes.users.notes,
          },
          {
            title: 'User Links',
            path: Routes.users.userLinks,
          },
        ],
      },
      {
        title: 'Sales',
        path: '',
        icon: ICONS.sales,
        children: [
          {
            path: Routes.subscriptions_sales_report,
            title: 'Subscriptions Report',
          },
          {
            title: 'Subscriptions Sales',
            path: Routes.subscriptions_sales_v2(),
          },
          {
            path: Routes.consumables_sales_report,
            title: 'Consumables Report',
          },
          {
            title: 'Consumables Sales',
            path: Routes.consumables_sales_list(),
          },
          {
            title: 'refunds',
            path: Routes.refunds,
          },
        ],
      },
      {
        title: 'Marketplace',
        path: '',
        icon: ICONS.pools,
        children: [
          {
            title: 'transaction pools',
            path: Routes.transactions.pools,
          },
          {
            title: 'Transactions',
            path: Routes.transactions.list,
          },
          {
            title: 'Pool Status',
            path: Routes.transactions.poolsStatus,
          },
          {
            title: 'payment requests',
            path: Routes.transactions.payment_requests,
          },
          {
            title: 'NFT Pack Deliveries',
            path: Routes.transactions.nft_pack_delivery,
          },
          {
            title: 'Coins Deliveries',
            path: Routes.transactions.coins_delivery,
          },
          {
            title: 'Coins Expiring',
            path: Routes.transactions.coins_expiring,
          },
        ],
      },
      {
        title: 'Tradeable Items',
        path: '',
        icon: ICONS.collections,
        children: [
          {
            title: 'Collections',
            path: Routes.objects.collections,
          },
          {
            title: 'Items',
            path: Routes.objects.nfts,
          },
          {
            title: 'Nft Packs',
            path: Routes.products.nft_packs,
          },
          {
            title: 'Nfts Available For Nft Packs',
            path: Routes.objects.nfts_for_packs,
          },
        ],
      },
      {
        title: 'Game Center',
        path: '',
        icon: ICONS.game_center,
        children: [
          {
            title: 'Games',
            path: Routes.game_center.games,
          },
          {
            title: 'Categories',
            path: Routes.game_center.categories,
          },
          {
            title: 'Tags',
            path: Routes.game_center.tags,
          },
          {
            title: 'Games Analytics',
            path: Routes.game_center.analytics,
          },
          {
            title: 'Player Analytics',
            path: Routes.game_center.player_analytics,
          },
          {
            title: 'Games Sessions',
            path: Routes.game_center.sessions,
          },
          /*
          {
            title: 'Feed Analytics',
            path: Routes.game_center.feed_analytics,
          },
          */
          {
            title: 'Ratings',
            path: Routes.game_center.ratings,
          },
        ],
      },
      {
        title: 'Search',
        path: '',
        icon: ICONS.search,
        children: [
          {
            title: 'Searches',
            path: Routes.search.searches,
          },
          {
            title: 'Analytics',
            path: Routes.search.analytics,
          },
        ],
      },
      {
        title: 'Community',
        path: '',
        icon: ICONS.community,
        children: [
          {
            title: 'Clubs',
            path: Routes.comunity.clubs,
          },
        ],
      },
      {
        title: 'Channel',
        path: '',
        icon: ICONS.channels,
        children: [
          {
            title: 'Channels',
            path: Routes.channel.channels,
          },
          {
            title: 'Contents',
            path: Routes.channel.contents,
          },
          {
            title: 'Channels by sticky',
            path: Routes.channel.stickyChannels,
          },
        ],
      },
      /*
      {
        title: 'Submission',
        path: '',
        icon: ICONS.submission,
        children: [
          {
            title: 'Submissions',
            path: Routes.submission.submissions,
          },
        ],
      },
      */
      /*
      {
        title: 'Review',
        path: '',
        icon: ICONS.moderation,
        children: [
          {
            title: 'To Review',
            path: Routes.review.toReview,
          },
          {
            title: 'Channel',
            path: Routes.review.object(OBJECT_MODERATION_OBJECT_TYPE.CHANNEL),
          },
          {
            title: 'Channel Content',
            path: Routes.review.object(OBJECT_MODERATION_OBJECT_TYPE.VIDEO),
          },
          {
            title: 'Game',
            path: Routes.review.object(
              OBJECT_MODERATION_OBJECT_TYPE.GAME_VERSION
            ),
          },
          {
            title: 'Moderations',
            path: Routes.review.objectModerations,
          },
        ],
      },
      */
      {
        title: 'In-App Communication',
        path: '',
        icon: ICONS.insights,
        children: [
          {
            title: 'reports',
            path: Routes.reports,
          },
          {
            title: 'feedbacks',
            path: Routes.feedbacks,
          },
          {
            title: 'Communications',
            path: Routes.communications,
          },
        ],
      },
      {
        title: 'Products',
        path: '',
        icon: ICONS.products,
        children: [
          {
            title: 'Subscriptions',
            path: Routes.products.subscriptions,
          },
          {
            title: 'Consumables',
            path: Routes.products.consumables,
          },
        ],
      },
      {
        title: 'Configurations',
        path: '',
        icon: ICONS.configurations,
        children: [
          {
            title: 'Marketplace carousel',
            path: Routes.configurations.home_feeds,
          },
          {
            title: 'Video Feed',
            path: Routes.configurations.game_feed,
          },
          {
            title: 'Contests',
            path: Routes.configurations.contests,
          },
          {
            title: 'Regions',
            path: Routes.configurations.regions,
          },
          {
            title: 'App Configurations',
            path: Routes.configurations.app_configurations,
          },
          {
            title: 'Sanctioned Countries',
            path: Routes.configurations.sanctioned_countries,
          },
          {
            title: 'Collections For Packs',
            path: Routes.configurations.collectionsForPacks,
          },
        ],
      },
      {
        title: 'DB Consistency',
        path: '',
        icon: ICONS.DBConsistency,
        children: [
          {
            title: 'User Purges',
            path: Routes.db_consistency.user_purges,
          },
          {
            title: 'Wallets',
            path: Routes.db_consistency.wallet_consistency,
          },
          {
            title: 'Users without Wallets',
            path: Routes.db_consistency.users_without_wallet,
          },
          {
            title: "Blockchain API's Healthcheck",
            path: Routes.db_consistency.blockchain_healthchecks,
          },
          {
            title: 'Rev. Events with Failed Transactions',
            path: Routes.db_consistency.revenue_events_consistency,
          },
          {
            title: 'Not Processed Consumables',
            path: Routes.db_consistency.not_processed_consumables,
          },
          {
            title: 'Nft Ownership Mismatch',
            path: Routes.db_consistency.nft_ownership,
          },
          {
            title: 'Admin Nfts Transfer',
            path: Routes.db_consistency.admin_nfts_transfer,
          },
        ],
      },
      {
        title: 'Old Screens',
        path: '',
        icon: ICONS.old_screens,
        children: [
          {
            title: 'old transactions',
            path: Routes.old_transactions,
          },
          {
            title: 'iap transactions',
            path: Routes.iap,
          },
        ],
      },
    ],
  },
];

export default navConfig;
