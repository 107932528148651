import { Avatar, Link, Stack, TableCell, TableRow } from '@mui/material';
import { capitalCase } from 'change-case';
import Highlighter from 'react-highlight-words';
import { Link as RouterLink } from 'react-router-dom';
import LinkToUser from 'src/components/shared/LinkToUser';
import { NFT } from 'src/lib/types/nft';
import { formatDate } from 'src/lib/utils/formatters';
import {
  getCollectionHumanAnalysisStatusColor,
  getCollectionReviewStatusColor,
} from 'src/lib/utils/utils';
import Routes from 'src/routes/Routes';
import Label from 'src/template/components/Label';

const highlightStyle = {
  backgroundColor: '#FC6600', // Dark Orange
  // fontWeight: 'bold',
  color: 'white',
};

function searchFormatter(
  text: string,
  colName: string,
  searchHighlight: {
    searchColumn: string;
    searchKeyword: string;
  }
) {
  return searchHighlight.searchColumn === colName ? (
    <Highlighter
      searchWords={[searchHighlight.searchKeyword]}
      textToHighlight={text}
      highlightStyle={highlightStyle}
    />
  ) : (
    text
  );
}

export default function NftsTableRow({
  nft,
  searchHighlight,
}: {
  nft: NFT;
  searchHighlight: { searchColumn: string; searchKeyword: string };
}) {
  return (
    <TableRow key={nft.uuid} hover>
      <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
        <Avatar
          alt={nft.name}
          src={nft.fileUrl}
          variant="rounded"
          sx={{ mr: 2 }}
        />
        <Link
          component={RouterLink}
          to={Routes.nft(nft.uuid)}
          color={nft.name ? 'inherit' : 'text.secondary'}
          underline="hover"
          display="inline-block"
        >
          {searchFormatter(nft.name ?? 'empty', 'name', searchHighlight)}
        </Link>
      </TableCell>
      <TableCell align="center">
        <Label
          variant="ghost"
          color={nft.active ? 'success' : 'error'}
          sx={{ textTransform: 'capitalize' }}
        >
          {nft.active ? 'Active' : 'Inactive'}
        </Label>
      </TableCell>
      <TableCell align="center">
        <Stack alignItems="center" spacing={1}>
          <Label
            variant="ghost"
            color={getCollectionReviewStatusColor(nft.reviewStatus)}
            sx={{ textTransform: 'capitalize' }}
          >
            {capitalCase(nft.reviewStatus)}
          </Label>
          {nft.humanAnalysis && (
            <Label
              variant="filled"
              color={getCollectionHumanAnalysisStatusColor(nft.humanAnalysis)}
            >
              {capitalCase(nft.humanAnalysis)}
            </Label>
          )}
        </Stack>
      </TableCell>
      <TableCell align="center">
        {searchFormatter(
          capitalCase(nft.blockchain),
          'blockchain',
          searchHighlight
        )}
      </TableCell>
      <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
        <Avatar
          alt={nft.collection?.name}
          src={nft.collection?.coverFileUrl}
          variant="rounded"
          sx={{ mr: 2 }}
        />
        <Link
          component={RouterLink}
          to={Routes.collection(nft.collection?.uuid)}
          color={nft.collection?.name ? 'inherit' : 'text.secondary'}
          underline="hover"
          display="inline-block"
        >
          {nft.collection?.name ?? 'empty'}{' '}
        </Link>
      </TableCell>
      <TableCell>
        <LinkToUser
          user={nft.collection?.user}
          blockchain={nft.collection?.blockchain}
          externalWallet={nft.collection?.externalWallet}
        />
      </TableCell>
      <TableCell align="right">{formatDate(nft.updatedAt)}</TableCell>
      <TableCell align="right">{formatDate(nft.createdAt)}</TableCell>
    </TableRow>
  );
}
