import useModalStore from 'src/lib/stores/useModalStore';
import SendChannelContentToFeedView from '../../view/SendVideoFeed';

export default function useChannelContentTable() {
  const openModal = useModalStore((state) => state.openModal);

  const handleOpenSendToAppVideoFeedModal = (uuid: string) => {
    openModal(<SendChannelContentToFeedView uuid={uuid} />);
  };

  return {
    handleOpenSendToAppVideoFeedModal,
  };
}
