import { Card, CardContent, Container, Tab, Tabs } from '@mui/material';
import { capitalCase } from 'change-case';
import Flex from 'src/components/shared/Flex';
import { OBJECT_MODERATION_REVIEW_STATUS } from 'src/lib/types/objectModeration';
import HeaderBreadcrumbs from 'src/template/components/HeaderBreadcrumbs';
import ChannelContentTable from '../../components/ChannelContentTable';
import ChannelContentTableHeader from '../../components/ChannelContentTable/header';
import useChannelVideos from './useView';

const STATUS_TABS = [
  {
    value: 'all',
    label: 'All',
  },
  ...Object.values(OBJECT_MODERATION_REVIEW_STATUS).map((value) => ({
    value,
    label: capitalCase(value),
  })),
];

export default function ChannelVideos() {
  const {
    data,
    isLoading,
    params,
    onChangeFeedStatus,
    currentTab,
    onChangeTab,
  } = useChannelVideos();

  return (
    <Container maxWidth="xl">
      <HeaderBreadcrumbs
        heading="Channel Videos"
        links={[{ name: 'Channel Videos' }]}
      />

      <Card>
        <Flex sx={{ px: 2, bgcolor: 'background.neutral' }}>
          <Tabs
            allowScrollButtonsMobile
            variant="scrollable"
            scrollButtons="auto"
            value={currentTab}
            onChange={onChangeTab}
          >
            {STATUS_TABS.map((tab) => (
              <Tab
                disableRipple
                key={tab.value}
                label={tab.label}
                value={tab.value}
              />
            ))}
          </Tabs>
        </Flex>

        <CardContent>
          <ChannelContentTableHeader params={params} />
          <ChannelContentTable
            data={data}
            isLoading={isLoading}
            params={params}
            onChangeFeedStatus={onChangeFeedStatus}
          />
        </CardContent>
      </Card>
    </Container>
  );
}
