import { useParams } from 'react-router';
import useTabs from 'src/template/hooks/useTabs';
import { UserClubRelations } from './types';

export default function useUserClubs() {
  const { uuid: userUuid } = useParams<{ uuid: string }>();
  const { currentTab: currentRelation, onChangeTab: onChangeRelation } =
    useTabs(UserClubRelations.MEMBER, {
      queryName: 'relation',
    });

  return {
    userUuid,
    currentRelation,
    onChangeRelation,
  };
}
