/* eslint-disable no-promise-executor-return */
import { ContentCopy, Reviews } from '@mui/icons-material';
import { Box, MenuItem, Typography } from '@mui/material';

import Link from 'src/components/shared/Link';
import MagicTable from 'src/components/shared/MagicTable/MagicTable';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';

import Routes from 'src/routes/Routes';

import { capitalCase } from 'change-case';
import CopyToClipboard from 'react-copy-to-clipboard';
import { ObjectsToModerateResponse } from 'src/components/modules/Review/services/fetchObjectsToReview';
import MagicTableUserCell from 'src/components/shared/MagicTable/UserCell';
import MoreMenu from 'src/components/shared/MoreMenu';
import useModalStore from 'src/lib/stores/useModalStore';
import { FeedItem } from 'src/lib/types/FeedItem';
import { Channel } from 'src/lib/types/channel';
import {
  OBJECT_MODERATION_OBJECT_TYPE,
  OBJECT_MODERATION_REVIEW_STATUS,
  ObjectVisibleStatus,
} from 'src/lib/types/objectModeration';
import { formatUTCDate } from 'src/lib/utils/formatUTCDate';
import { reviewStatusLabel, visibleStatusLabel } from '../../../utils/enums';
import VideosReviewForm from '../../ReviewForm';

type ChannelsToReviewTableProps = {
  data?: ObjectsToModerateResponse;
  isLoading: boolean;
  params: { page: number; size: number }; // 'orderBy' | 'sortOrder'
};

export default function ChannelsToReviewTable(
  props: ChannelsToReviewTableProps
) {
  const {
    data,
    isLoading,
    params: { page, size },
  } = props;
  const { addParam, removeParam } = useURLSearchParams();

  const { openModal } = useModalStore();

  const openReviewModal = (
    objectUuid: string,
    objectType: OBJECT_MODERATION_OBJECT_TYPE
  ) => {
    openModal(
      <VideosReviewForm objectUuid={objectUuid} objectType={objectType} />
    );
  };

  return (
    <MagicTable.Container>
      <Box px={2}>
        <MagicTable
          loading={isLoading}
          pageSize={size}
          page={page}
          paginationMode="server"
          onPageChange={(newPage) => {
            addParam('page', newPage);
          }}
          onPageSizeChange={(newSize) => {
            addParam('size', newSize);
          }}
          onSortModelChange={(model) => {
            const currentModel = model[0];
            if (currentModel) {
              addParam('orderBy', currentModel.field);
              if (currentModel.sort) addParam('order', currentModel.sort);
            } else {
              removeParam('order');
              removeParam('orderBy');
            }
          }}
          /* sortModel={[
            {
              field: orderBy,
              sort: sortOrder as GridSortDirection,
            },
          ]} */
          rows={data?.body ?? []}
          rowCount={data?.meta.totalCount ?? 0}
          columns={[
            {
              field: 'humanAnalysisNeeded',
              headerName: 'Human Analysis Needed',
              flex: 1 / 2,
              sortable: false,
              renderCell({ row }: { row: Channel }) {
                return (
                  <Typography variant="body2" color="text.secondary">
                    {row.humanAnalysisNeeded}
                  </Typography>
                );
              },
            },
            {
              field: 'name',
              headerName: 'Name',
              sortable: false,
              flex: 1 / 2,
              renderCell({ row }: { row: Channel }) {
                return (
                  <Link to={Routes.channel.content(row.uuid)}>
                    {row.name ? row.name : '--'}
                  </Link>
                );
              },
            },
            {
              field: 'owner',
              headerName: 'Owner',
              sortable: false,
              renderCell({ row }) {
                const hasOwner = !!row.owner;
                return hasOwner ? (
                  <MagicTableUserCell user={row.owner} />
                ) : (
                  '--'
                );
              },
            },
            {
              field: 'type',
              headerName: 'Type',
              sortable: true,
              valueGetter: (_params) => capitalCase(_params.value),
            },
            {
              field: 'createdAt',
              headerName: 'Created At',
              flex: 1 / 2,
              sortable: true,
              sortingOrder: ['asc', 'desc'],
              renderCell({ row }: { row: FeedItem }) {
                return (
                  <Typography variant="body2" color="text.secondary">
                    {formatUTCDate(row.updatedAt)}
                  </Typography>
                );
              },
            },
            {
              field: 'userStatus',
              headerName: 'User Status',
              flex: 1 / 2,
              sortable: false,
              renderCell({ row }: { row: Channel }) {
                return (
                  <Typography variant="body2" color="text.secondary">
                    {row.userStatus}
                  </Typography>
                );
              },
            },
            {
              field: 'reviewStatus',
              headerName: 'Review Status',
              flex: 1 / 2,
              sortable: false,
              renderCell({ row }: { row: Channel }) {
                return (
                  <Typography variant="body2" color="text.secondary">
                    {
                      reviewStatusLabel[
                        row.reviewStatus as OBJECT_MODERATION_REVIEW_STATUS
                      ]
                    }
                  </Typography>
                );
              },
            },
            {
              field: 'visibleStatus',
              headerName: 'Visible Status',
              flex: 1 / 2,
              sortable: false,
              renderCell({ row }: { row: Channel }) {
                return (
                  <Typography variant="body2" color="text.secondary">
                    {
                      visibleStatusLabel[
                        row.visibleStatus as ObjectVisibleStatus
                      ]
                    }
                  </Typography>
                );
              },
            },
            {
              field: 'actions',
              headerName: 'Actions',
              align: 'center',
              sortable: false,
              flex: 0,
              renderCell({ row }: { row: Channel }) {
                return (
                  <MoreMenu>
                    <CopyToClipboard text={row.uuid}>
                      <MenuItem aria-label="copy feed uuid">
                        <ContentCopy />
                        Copy UUID
                      </MenuItem>
                    </CopyToClipboard>
                    {row.lastObjectModerationUuid && (
                      <MenuItem
                        onClick={() =>
                          openReviewModal(
                            row.lastObjectModerationUuid!,
                            OBJECT_MODERATION_OBJECT_TYPE.CHANNEL
                          )
                        }
                      >
                        <Reviews />
                        Review
                      </MenuItem>
                    )}
                  </MoreMenu>
                );
              },
            },
          ]}
        />
      </Box>
    </MagicTable.Container>
  );
}
