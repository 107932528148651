import { useParams } from 'react-router';
import { DEFAULT_INITIAL_PAGE_SIZE } from 'src/lib/constants/pagination';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';
import fetchUserTransfers from '../../../services/fetchUserTransfers';

export default function useUserTransfersView() {
  const { uuid } = useParams<{ uuid: string }>();
  const { allSearchParams, removeParam, addParam } = useURLSearchParams({
    page: '0',
    size: DEFAULT_INITIAL_PAGE_SIZE.toString(),
  });

  const { data, isLoading } = fetchUserTransfers(
    uuid!,
    getPossibleParams(allSearchParams)
  );

  function onChangeFilter(field: string, value: string | null | undefined) {
    if (!value) removeParam(field);
    else addParam(field, value);
  }

  return {
    data,
    isLoading,
    page: Number(allSearchParams.page!),
    size: Number(allSearchParams.size!),
    onChangeFilter,
    filter: {
      startDate: allSearchParams.startDate,
      endDate: allSearchParams.endDate,
    },
  };
}

function getPossibleParams(
  allSearchParams: Record<string, string | undefined>
): Parameters<typeof fetchUserTransfers>[1] {
  const { page, size, startDate, endDate } = allSearchParams;
  return {
    filter: {
      createdAt: {
        gte: startDate ? `${startDate}T00:00:00.000Z` : '',
        lte: startDate ? `${endDate}T23:59:59.000Z` : '',
      },
    },
    size: Number(size),
    page: Number(page),
  };
}
