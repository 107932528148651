/* eslint-disable import/prefer-default-export */
import { SubmissionFormStatuses } from 'src/lib/types/formReviewStatus';
import {
  OBJECT_MODERATION_AI_REVIEW,
  OBJECT_MODERATION_HUMAN_ANALYSIS_NEEDED,
  OBJECT_MODERATION_HUMAN_REVIEW,
  OBJECT_MODERATION_OBJECT_TYPE,
  OBJECT_MODERATION_REVIEW_STATUS,
  ObjectActionablesStatus,
  ObjectUserStatus,
  ObjectVisibility,
  ObjectVisibleStatus,
} from 'src/lib/types/objectModeration';
import { LabelColor } from 'src/template/components/Label';

export const getReviewLabelColor = (
  status: SubmissionFormStatuses
): LabelColor => {
  switch (status) {
    case SubmissionFormStatuses.REVIEW_APPROVED:
      return 'success';
    case SubmissionFormStatuses.REVIEW_REJECTED:
      return 'error';
    case SubmissionFormStatuses.AWAITING_REVIEW:
      return 'warning';
    default:
      return 'secondary';
  }
};

export const labelByObjectType: Record<OBJECT_MODERATION_OBJECT_TYPE, string> =
  {
    [OBJECT_MODERATION_OBJECT_TYPE.CHANNEL]: 'Channel',
    [OBJECT_MODERATION_OBJECT_TYPE.CLUB]: 'Club',
    [OBJECT_MODERATION_OBJECT_TYPE.COLLECTION]: 'Collection',
    [OBJECT_MODERATION_OBJECT_TYPE.GAME_VERSION]: 'Game',
    [OBJECT_MODERATION_OBJECT_TYPE.NFT]: 'NFT',
    [OBJECT_MODERATION_OBJECT_TYPE.USER]: 'User',
    [OBJECT_MODERATION_OBJECT_TYPE.VIDEO]: 'Video',
  };

export const colorByReviewStatus: Record<
  OBJECT_MODERATION_REVIEW_STATUS,
  LabelColor
> = {
  [OBJECT_MODERATION_REVIEW_STATUS.UNREVIEWED]: 'default',
  [OBJECT_MODERATION_REVIEW_STATUS.AI_REJECTED]: 'error',
  [OBJECT_MODERATION_REVIEW_STATUS.AI_INCONCLUSIVE]: 'warning',
  [OBJECT_MODERATION_REVIEW_STATUS.AI_APPROVED]: 'success',
  [OBJECT_MODERATION_REVIEW_STATUS.REJECTION]: 'error',
  [OBJECT_MODERATION_REVIEW_STATUS.LIMITED_APPROVAL]: 'warning',
  [OBJECT_MODERATION_REVIEW_STATUS.FULL_APPROVAL]: 'success',
};

export const colorByObjectVisibility: Record<ObjectVisibility, LabelColor> = {
  [ObjectVisibility.EVERYWHERE]: 'success',
  [ObjectVisibility.LIMITED]: 'warning',
  [ObjectVisibility.PRIVATE]: 'error',
  [ObjectVisibility.HIDDEN]: 'error',
  [ObjectVisibility.NOT_VISIBLE]: 'error',
};

export const colorByAIReview: Record<OBJECT_MODERATION_AI_REVIEW, LabelColor> =
  {
    [OBJECT_MODERATION_AI_REVIEW.AI_NOT_SET]: 'default',
    [OBJECT_MODERATION_AI_REVIEW.AI_REJECTED]: 'error',
    [OBJECT_MODERATION_AI_REVIEW.AI_INCONCLUSIVE]: 'warning',
    [OBJECT_MODERATION_AI_REVIEW.AI_APPROVED]: 'success',
  };

export const colortByActionablesStatus: Record<
  ObjectActionablesStatus,
  LabelColor
> = {
  [ObjectActionablesStatus.ON]: 'success',
  [ObjectActionablesStatus.OFF]: 'error',
  [ObjectActionablesStatus.ONLY_PRO]: 'warning',
};

export const colorByVisibleStatus: Record<ObjectVisibleStatus, LabelColor> = {
  [ObjectVisibleStatus.PUBLIC]: 'success',
  [ObjectVisibleStatus.PRIVATE]: 'error',
  [ObjectVisibleStatus.IN_REVIEW]: 'warning',
  [ObjectVisibleStatus.NEEDS_REVISION]: 'warning',
};

export const colorByUserStatus: Record<ObjectUserStatus, LabelColor> = {
  [ObjectUserStatus.PUBLIC]: 'success',
  [ObjectUserStatus.PRIVATE]: 'error',
};

export const colorByHumanAnalysisNeeded: Record<
  OBJECT_MODERATION_HUMAN_ANALYSIS_NEEDED,
  LabelColor
> = {
  [OBJECT_MODERATION_HUMAN_ANALYSIS_NEEDED.NEW]: 'success',
  [OBJECT_MODERATION_HUMAN_ANALYSIS_NEEDED.UPDATED]: 'warning',
  [OBJECT_MODERATION_HUMAN_ANALYSIS_NEEDED.REPORTED]: 'warning',
  [OBJECT_MODERATION_HUMAN_ANALYSIS_NEEDED.FALSE]: 'error',
};

export const colorByHumanReview: Record<
  OBJECT_MODERATION_HUMAN_REVIEW,
  LabelColor
> = {
  [OBJECT_MODERATION_HUMAN_REVIEW.FULL_APPROVAL]: 'success',
  [OBJECT_MODERATION_HUMAN_REVIEW.LIMITED_APPROVAL]: 'warning',
  [OBJECT_MODERATION_HUMAN_REVIEW.REJECTION]: 'error',
  [OBJECT_MODERATION_HUMAN_REVIEW.NOT_SET]: 'default',
};
