import { useCallback, useEffect } from 'react';
import { DEFAULT_INITIAL_PAGE_SIZE } from 'src/lib/constants/pagination';
import useNotify from 'src/lib/hooks/useNotify';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';
import useModalStore from 'src/lib/stores/useModalStore';
import { AdminListGamesRow } from 'src/lib/types/gameRow';
import useTabs from 'src/template/hooks/useTabs';
import { GameEmitter } from '../../events/GameEmitter';
import { downloadGamesCSV as downloadActiveGamesCSV } from '../../services/downloadCsv';
import { fetchGames } from '../../services/fetchGames';
import { UpdateGameStatus } from '../../services/updateGameStatus';
import CreateGameView from '../Create';
import DeleteGameView from '../Delete';
import UpdateGameView from '../Update';

export default function useGames() {
  const openModal = useModalStore((state) => state.openModal);
  const notify = useNotify();

  const { currentTab, onChangeTab } = useTabs('all', {
    queryName: 'tab',
  });

  const handleOpenCreateModal = () => {
    openModal(<CreateGameView />);
  };
  const handleOpenUpdateModal = (game: AdminListGamesRow) => {
    openModal(<UpdateGameView game={game} />);
  };
  const handleOpenDeleteModal = (uuid: string) => {
    openModal(<DeleteGameView uuid={uuid} />);
  };

  const handleDownloadActiveGamesCSV = async () => {
    const error = await downloadActiveGamesCSV();
    return error;
  };

  const { allSearchParams } = useURLSearchParams(initialParams);

  const params = {
    page: +(allSearchParams.page ?? initialParams.page),
    size: +(allSearchParams.size ?? initialParams.size),
    orderBy: allSearchParams.orderBy ?? initialParams.orderBy,
    sortOrder: allSearchParams.order ?? initialParams.order,
    searchBy: allSearchParams.searchBy ?? initialParams.searchBy,
    query: allSearchParams.query ?? initialParams.query,
  };

  const { data, isLoading, mutate } = fetchGames({
    page: params.page,
    size: params.size,

    ...(params.orderBy &&
      params.sortOrder && {
        order: {
          [params.orderBy]: params.sortOrder,
        },
      }),

    filter: {
      ...(params.searchBy &&
        params.query && {
          [params.searchBy]: {
            like: `${params.query}%`,
          },
        }),
      ...(currentTab !== 'all' && {
        reviewStatus: currentTab,
      }),
    },
  });

  async function handleChangeGameStatus(
    uuid: string,
    currentStatus: 'active' | 'inactive'
  ) {
    const newStatus = currentStatus === 'active' ? 'inactive' : 'active';

    await UpdateGameStatus(uuid, {
      status: newStatus,
    })
      .then(() => {
        notify('Changed game status successfully', {
          variant: 'success',
        });

        const newData = data?.rows.map((game) => {
          if (game.uuid === uuid) {
            return {
              ...game,
              status: newStatus,
            };
          }
          return game;
        }) as AdminListGamesRow[];

        mutate({ rows: newData, count: data?.count ?? 0 });
      })
      .catch(() => {
        notify('Failed to change game status', {
          variant: 'error',
        });
      });
  }

  const refetchData = useCallback(() => {
    mutate?.();
  }, []);

  useEffect(() => {
    GameEmitter.on('GameCreated', refetchData);
    GameEmitter.on('GameUpdated', refetchData);
    GameEmitter.on('GameDeleted', refetchData);
    GameEmitter.on('GameStatusChanged', refetchData);
    return () => {
      GameEmitter.off('GameCreated', refetchData);
      GameEmitter.off('GameUpdated', refetchData);
      GameEmitter.off('GameDeleted', refetchData);
    };
  }, [refetchData]);

  return {
    data,
    isLoading,
    params,
    handleOpenCreateModal,
    handleOpenUpdateModal,
    handleOpenDeleteModal,
    handleChangeGameStatus,
    handleDownloadActiveGamesCSV,
    currentTab,
    onChangeTab,
  };
}

const initialParams = {
  page: '0',
  size: DEFAULT_INITIAL_PAGE_SIZE.toString(),
  order: 'desc',
  orderBy: 'createdAt',
  searchBy: 'name',
  query: '',
};

export type FetchGamesQueryParams = {
  page: number;
  size: number;
  orderBy: string;
  searchBy: string;
  sortOrder: string;
};
