import { CircularProgress, MenuItem, Stack, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import Center from 'src/components/shared/Center';
import TimelineChart from 'src/components/shared/Charts/TimelineChart/TimelineChart';
import CloseableAlert from 'src/components/shared/CloseableAlert';
import { fetchUserNftActivityTimeline } from 'src/lib/services/users';

export default function NftActivityTimelineChart({
  userId,
}: NftActivityTimelineProps) {
  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(new Date().getMonth());
  const [monthDays, setMonthDays] = useState(getMonthDays(year, month));

  const { data, error } = fetchUserNftActivityTimeline(userId, year, month);

  useEffect(() => {
    setMonthDays(getMonthDays(year, month));
  }, [year, month, setMonthDays]);

  if (error) {
    return (
      <CloseableAlert severity="error">
        Failed loading the NFT activity timeline chart.
      </CloseableAlert>
    );
  }

  return data ? (
    <TimelineChart
      title="NFT Activity Timeline"
      dates={monthDays}
      data={[
        {
          name: 'NFT Sales',
          data: data.salesTimeline,
        },
        {
          name: 'NFT Purchases',
          data: data.purchasesTimeline,
        },
        {
          name: 'NFT Transfers',
          data: data.transferTimeline,
        },
      ]}
      currentDate={year}
      action={
        <Stack direction="row" gap={1} minWidth="300px">
          <TextField
            select
            fullWidth
            size="small"
            value={year}
            onChange={(e) => setYear(Number(e.target.value))}
          >
            {years.map((y) => (
              <MenuItem key={y} value={y}>
                {y}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            select
            fullWidth
            size="small"
            value={month}
            onChange={(e) => setMonth(Number(e.target.value))}
          >
            {months.map((m, i) => (
              <MenuItem key={m} value={i}>
                {m}
              </MenuItem>
            ))}
          </TextField>
        </Stack>
      }
    />
  ) : (
    <Center>
      <CircularProgress />
    </Center>
  );
}

const getMonthDays = (year: number, month: number) =>
  new Array(new Date(year, month + 1, 0).getDate())
    .fill(0)
    .map((_, i) => i + 1);

type NftActivityTimelineProps = {
  userId: string;
};

const years = [2022, 2023];
const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];
