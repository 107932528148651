import { Card, CardContent, CardHeader, CardProps } from '@mui/material';
import { merge } from 'lodash';
import { ReactNode } from 'react';
import ReactApexChart from 'react-apexcharts';
import { BaseOptionChart } from 'src/template/components/chart';

export type TimelineChartData = Array<{
  name: string;
  data: number[];
  color?: string;
}>;

export type TimelineChartProps = {
  title: string;
  subtitle?: string;
  componentProps?: CardProps;
  dates: string[] | number[];
  data: TimelineChartData;
  currentDate: string | number;
  action?: ReactNode;
};

export default function TimelineChart({
  title,
  componentProps,
  dates,
  subtitle,
  data,
  action,
}: TimelineChartProps) {
  const chartOptions = merge(BaseOptionChart(), {
    xaxis: {
      categories: dates,
    },
  });

  return (
    <Card {...componentProps}>
      <CardHeader title={title} subheader={subtitle} action={action} />
      <CardContent>
        <ReactApexChart
          type="line"
          series={data}
          options={chartOptions}
          height={364}
        />
      </CardContent>
    </Card>
  );
}
