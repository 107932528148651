import { useParams } from 'react-router';
import { fetchNftPackDeliveries } from 'src/components/modules/NFTPacks/services/fetchNftPackDeliveries';
import { DEFAULT_INITIAL_PAGE_SIZE } from 'src/lib/constants/pagination';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';

export default function useUserNftPackDeliveriesView() {
  const { uuid } = useParams<{ uuid: string }>();
  const { allSearchParams, removeParam, addParam } = useURLSearchParams({
    page: '0',
    size: DEFAULT_INITIAL_PAGE_SIZE.toString(),
  });

  const { data, isLoading } = fetchNftPackDeliveries(
    getPossibleParamsNftPack(uuid, allSearchParams)
  );

  function onChangeFilter(field: string, value: string | null | undefined) {
    if (!value) removeParam(field);
    else addParam(field, value);
  }

  return {
    data,
    isLoading,
    page: Number(allSearchParams.page!),
    size: Number(allSearchParams.size!),
    onChangeFilter,
    filter: {
      startDate: allSearchParams.startDate,
      endDate: allSearchParams.endDate,
    },
  };
}

function getPossibleParamsNftPack(
  uuid: string | undefined,
  allSearchParams: Record<string, string | undefined>
): Parameters<typeof fetchNftPackDeliveries>[0] {
  const { page, size, startDate, endDate } = allSearchParams;
  return {
    filter: {
      /*
        TODO: Add date filters to the endpoint (sticky-API)
        startDate: `${startDate}T00:00:00.000Z`,
        endDate: `${endDate}T23:59:59.000Z`,
      */
      'user.uuid': uuid,
    },
    size: Number(size),
    page: Number(page),
  };
}
