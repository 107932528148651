import { BracketFilterParams } from 'src/lib/types/bracketFilterParams';
import { Order } from 'src/lib/utils/query/types';

export const getSortPropsFromBracketFilterParams = (
  filter: BracketFilterParams,
  setFilter: (filter: BracketFilterParams) => void
) => ({
  order: filter.order,
  orderBy: filter.orderBy,
  onSort: ({ order, orderBy }: { order: Order; orderBy: string }) =>
    setFilter({
      ...filter,
      order,
      orderBy,
    }),
});
