import { Card } from '@mui/material';
import GameRatings from 'src/components/modules/GameRatings/views/List';
import { PlayerSessionStats } from 'src/lib/types/gameSession';

type Props = {
  data: PlayerSessionStats;
  isLoading: boolean;
};
export default function UserGameRatingsView({
  data,
  isLoading,
}: Props): JSX.Element {
  return (
    <Card>
      <GameRatings showHeader={false} />
    </Card>
  );
}
