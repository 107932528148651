import { Card, Stack, Tab, Tabs } from '@mui/material';
import { capitalCase } from 'change-case';
import { Suspense } from 'react';
import { Navigate, useParams } from 'react-router';
import Flex from 'src/components/shared/Flex';
import Loader from 'src/components/shared/Loader';
import Routes from 'src/routes/Routes';
import HeaderBreadcrumbs from 'src/template/components/HeaderBreadcrumbs';
import LoadingScreen from 'src/template/components/LoadingScreen';
import GAME_TABS from './gameTabs';
import useGameDetails from './useView';

export default function GameDetails() {
  const { gameUuid: uuid } = useParams<'gameUuid'>();
  if (!uuid) return <Navigate to={Routes.game_center.games} replace />;

  const { game, currentTab, onChangeTab } = useGameDetails();

  return game ? (
    <>
      <HeaderBreadcrumbs
        heading={game.name || 'Empty Name'}
        links={[
          { name: 'Games', href: Routes.game_center.games },
          { name: game.name || 'Empty Name' },
        ]}
      />

      <Card>
        <Stack pb={2}>
          <Flex sx={{ px: 2, bgcolor: 'background.neutral' }} mb={2}>
            <Tabs
              allowScrollButtonsMobile
              variant="scrollable"
              scrollButtons="auto"
              value={currentTab}
              onChange={onChangeTab}
            >
              {GAME_TABS.map((tab) => (
                <Tab
                  disableRipple
                  key={tab.value}
                  value={tab.value}
                  icon={tab.icon}
                  label={capitalCase(tab.value)}
                />
              ))}
            </Tabs>
          </Flex>

          <Suspense fallback={<Loader />}>
            {GAME_TABS.map(
              (tab) => tab.value === currentTab && tab.component(game)
            )}
          </Suspense>
        </Stack>
      </Card>
    </>
  ) : (
    <LoadingScreen />
  );
}
