import { DeepPartial, Path } from 'react-hook-form';
import { Operators } from 'src/lib/types/Operators';
import { Product, WithProduct } from 'src/lib/types/product';
import { User, WithOptionalUser } from 'src/lib/types/users';
import { getMessageFromError } from 'src/lib/utils/error';
import { parseSearchParams } from 'src/lib/utils/parseSearchParams';
import useSWR, { SWRResponse } from 'swr';
import { RevenueEvent } from '../types/RevenueEvent';

type FilterKeys =
  | Path<{ user: User }>
  | Path<{ product: Product }>
  | keyof RevenueEvent;

type PossibleParams = {
  filter: Record<FilterKeys, string | number | Operators>;
  size: number | string;
  page: number | string;
  order: Record<string, string>;
};

export function fetchSubscriptions(
  params?: DeepPartial<PossibleParams>
): SWRResponse<
  WithProduct<WithOptionalUser<RevenueEvent & { isActive: boolean }>>[]
> {
  const parsedParams = parseSearchParams(params ?? {});
  const { error, ...response } = useSWR(
    `admin/revenue-events/subscriptions?${parsedParams}`
  );

  return {
    ...response,
    error: error ? getMessageFromError(error) : undefined,
  };
}
