import {
  Card,
  InputAdornment,
  MenuItem,
  Stack,
  Tab,
  Tabs,
  TextField,
} from '@mui/material';
import DebouncedField from 'src/components/shared/Inputs/DebouncedField';
import useDebounce from 'src/lib/hooks/useDebounce';
import { fetchNfts } from 'src/lib/services/nft';
import HeaderBreadcrumbs from 'src/template/components/HeaderBreadcrumbs';
import Iconify from 'src/template/components/Iconify';
import Scrollbar from 'src/template/components/Scrollbar';
import useTabs from 'src/template/hooks/useTabs';
import NftsTable from './NftsTable/NftsTable';
import NftsTableFooter from './NftsTable/NftsTableFooter';
import useNftsTable, { SearchForOptions } from './NftsTable/useNftsTable';

const REVIEW_STATUS_TABS = [{ value: 'all', label: 'All Nfts' }];

const SEARCH_OPTIONS = [
  {
    label: 'Nft name',
    value: 'name',
  },
  {
    label: 'Nft uuid',
    value: 'uuid',
  },
  {
    label: 'Nft Description',
    value: 'description',
  },
  {
    label: `Contract Address`,
    value: 'mintToken',
  },
  /*
  TODO: add search by creator and owner
  {
    label: 'Creator Name',
    value: 'collection.user.name',
  },
  {
    label: 'Creator Slug',
    value: 'collection.user.slug',
  },
  {
    label: 'Creator Email',
    value: 'collection.user.email',
  },
  */
  {
    label: 'Blockchain',
    value: 'blockchain',
  },
];

export default function NftsSearch() {
  const { currentTab: reviewStatus, onChangeTab: onChangeReviewStatus } =
    useTabs('all');

  const { orderBy, order, page, query, searchFor, size } = useNftsTable(
    (state) => state.filter
  );

  const [setQuery, setSearchFor] = useNftsTable((state) => [
    state.setQuery,
    state.setSearchFor,
  ]);

  const filter: any = {};
  const filterQuery = useDebounce(query, 500);
  if (searchFor && filterQuery !== '') {
    filter[searchFor] = {
      like: `%${filterQuery}%`,
    };
  }
  const {
    data: nfts,
    loading,
    error,
  } = fetchNfts({
    filter,
    page,
    size,
  });

  /*
    For now, we're disabling the total count update in pagination to improve
    performance, since the Nfts table has over a million rows
  */
  // const { total } = fetchNftsCount(filter);
  const total = 1845400; // as of Jan. 2024

  const searchHighlight = {
    searchColumn: searchFor,
    searchKeyword: filterQuery,
  };

  return (
    <>
      <HeaderBreadcrumbs
        heading="Nfts Search"
        links={[{ name: 'Nfts Search' }]}
      />
      <Card>
        <Tabs
          allowScrollButtonsMobile
          variant="scrollable"
          scrollButtons="auto"
          value={reviewStatus}
          onChange={onChangeReviewStatus}
          sx={{ px: 2, bgcolor: 'background.neutral' }}
        >
          {REVIEW_STATUS_TABS.map((tab) => (
            <Tab
              disableRipple
              key={tab.value}
              label={tab.label}
              value={tab.value}
            />
          ))}
        </Tabs>
        <Stack
          spacing={2}
          direction={{ xs: 'column', sm: 'row' }}
          sx={{ py: 2.5, px: 3 }}
        >
          <TextField
            fullWidth
            select
            label="Search for"
            value={searchFor}
            onChange={(e) => setSearchFor(e.target.value as SearchForOptions)}
            SelectProps={{
              MenuProps: {
                sx: { '& .MuiPaper-root': { maxHeight: 260 } },
              },
            }}
            sx={{
              maxWidth: { sm: 240 },
              textTransform: 'capitalize',
            }}
          >
            {SEARCH_OPTIONS.map((option) => (
              <MenuItem
                key={option.value}
                value={option.value}
                sx={{
                  mx: 1,
                  my: 0.5,
                  borderRadius: 0.75,
                  typography: 'body2',
                  textTransform: 'capitalize',
                }}
              >
                {option.label}
              </MenuItem>
            ))}
          </TextField>

          <DebouncedField
            fullWidth
            onDebounceChange={(e) => setQuery(e)}
            placeholder="Search nft..."
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify
                    icon="eva:search-fill"
                    sx={{ color: 'text.disabled', width: 20, height: 20 }}
                  />
                </InputAdornment>
              ),
            }}
          />
        </Stack>

        <Scrollbar>
          <NftsTable
            data={nfts}
            loading={loading}
            searchHighlight={searchHighlight}
          />
        </Scrollbar>

        <NftsTableFooter total={total || 0} page={page} rowsPerPage={size} />
      </Card>
    </>
  );
}
