import getAPIClient from 'src/lib/services/api/axios.config';
import { ServiceResponse } from 'src/lib/types/ServiceResponse';
import { Game } from 'src/lib/types/game';
import { getMessageFromError } from 'src/lib/utils/error';
import { CreateGameValidatedInput } from './createGame';

export type UpdateGameData = {
  name: string;
  description: string;
};

export async function UpdateGame(
  uuid: string,
  data: CreateGameValidatedInput
): Promise<ServiceResponse<Game>> {
  try {
    console.log('data', data);
    const api = await getAPIClient();
    const res = await api.put(`admin/game/${uuid}`, data);
    return {
      data: res.data,
      error: null,
    };
  } catch (e) {
    return {
      data: null,
      error: getMessageFromError(e),
    };
  }
}
