import { DataObject, ExpandMore, Label, Notes } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Stack,
  Typography,
} from '@mui/material';
import CopyToClipboard from 'react-copy-to-clipboard';
import Flex from 'src/components/shared/Flex';
import useNotify from 'src/lib/hooks/useNotify';
import { NFT, NFTMetadataAttribute, NFTMetadataType } from 'src/lib/types/nft';
import { getNFTName } from 'src/lib/utils/utils';

interface NFTMetadataProps {
  nft: NFT;
}
export default function NFTMetadata({ nft }: NFTMetadataProps): JSX.Element {
  const notify = useNotify();
  const metadata: NFTMetadataType = JSON.parse(nft.metadata);
  const metadataAttributes: NFTMetadataAttribute[] | undefined =
    typeof metadata?.attributes === 'string'
      ? JSON.parse(metadata?.attributes)
      : metadata?.attributes;
  const nftName = getNFTName(nft);
  const metadataProperties = [
    {
      label: 'Name',
      value: metadata.name,
    },
    {
      label: 'Image',
      value: metadata.image,
    },
  ];

  const handleShowCopied = () => {
    notify('Copied to clipboard');
  };
  return (
    <Box mt={1}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Flex gap={1}>
            <DataObject />
            <Typography variant="subtitle1">Metadata</Typography>
          </Flex>
        </AccordionSummary>
        <AccordionDetails id="panel2a-content">
          <Stack spacing={1}>
            {metadataProperties.map(({ label, value }) =>
              value ? (
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  key={label}
                  spacing={1}
                >
                  <Typography fontSize="14px">{label}:</Typography>
                  <CopyToClipboard onCopy={handleShowCopied} text={value}>
                    <Typography
                      noWrap
                      fontSize="14px"
                      fontWeight="bold"
                      textAlign="right"
                      sx={{
                        cursor: 'pointer',
                      }}
                    >
                      {value}
                    </Typography>
                  </CopyToClipboard>
                </Stack>
              ) : null
            )}
          </Stack>
        </AccordionDetails>
      </Accordion>
      {metadata.description && (
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel3a-content"
            id="panel3a-header"
          >
            <Flex gap={1}>
              <Notes />
              <Typography variant="subtitle1">About {nftName}</Typography>
            </Flex>
          </AccordionSummary>
          <AccordionDetails id="panel3a-content">
            <Typography
              sx={{
                wordBreak: 'break-all',
              }}
            >
              {metadata.description}
            </Typography>
          </AccordionDetails>
        </Accordion>
      )}
      {metadataAttributes && (
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel4a-content"
            id="panel4a-header"
          >
            <Flex gap={1}>
              <Label />
              <Typography variant="subtitle1">Properties</Typography>
            </Flex>
          </AccordionSummary>

          <AccordionDetails id="panel4a-content">
            {metadataAttributes.map((attribute) => (
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                key={attribute.trait_type}
                spacing={1}
              >
                <Typography fontSize="14px">{attribute.trait_type}:</Typography>
                <Typography
                  noWrap
                  fontSize="14px"
                  fontWeight="bold"
                  textAlign="right"
                >
                  {attribute.value}
                </Typography>
              </Stack>
            ))}
          </AccordionDetails>
        </Accordion>
      )}
    </Box>
  );
}
