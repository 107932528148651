import useUserTransfersView from './useView';
import UserTransfersView from './View';

export default function UserTransfers(): JSX.Element {
  const { data, isLoading, page, size, filter, onChangeFilter } =
    useUserTransfersView();
  return (
    <UserTransfersView
      data={data ?? []}
      isLoading={isLoading}
      page={page}
      size={size}
      filter={filter}
      onChangeFilter={onChangeFilter}
    />
  );
}
