import { PieChart, Timeline } from '@mui/icons-material';
import { Stack, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useState } from 'react';
import NftActivityTimelineChart from './NftActivityTimelineChart';
import SalesChart from './SalesChart';

type NftActivityViews = 'timeline' | 'pie';

export default function NftActivitySection({ userUuid }: { userUuid: string }) {
  const [nftActivityView, setNftActivityView] =
    useState<NftActivityViews>('pie');

  return (
    <Stack gap={2}>
      <Stack direction="row" justifyContent="center">
        <ToggleButtonGroup
          size="small"
          exclusive
          aria-label="change visualization mode"
          onChange={(_, value) => {
            if (value) {
              setNftActivityView(value);
            }
          }}
          value={nftActivityView}
        >
          <ToggleButton value="pie" size="small" aria-label="left aligned">
            <Stack direction="row" gap={1} alignItems="center">
              Pie
              <PieChart />
            </Stack>
          </ToggleButton>
          <ToggleButton value="timeline" size="small" aria-label="centered">
            <Stack direction="row" gap={1} alignItems="center">
              Timeline
              <Timeline />
            </Stack>
          </ToggleButton>
        </ToggleButtonGroup>
      </Stack>
      {nftActivityView === 'pie' ? (
        <SalesChart userUuid={userUuid} />
      ) : (
        <NftActivityTimelineChart userId={userUuid} />
      )}
    </Stack>
  );
}
