import {
  Card,
  CardContent,
  CardHeader,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  Switch,
  TextField,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import Button from 'src/components/shared/Buttons/CustomButton';
import Modal from 'src/components/shared/Modal';
import { reviewCollection } from 'src/lib/services/collections';
import { Collection, CollectionReview } from 'src/lib/types/collection';
import { getMessageFromError } from 'src/lib/utils/error';

type ReviewCollectionFormValues = CollectionReview;
type ReviewCollectionModalProps = {
  collection: Collection;
  open: boolean;
  onClose: () => void;
};

export default function ReviewCollectionModal({
  collection,
  open,
  onClose,
}: ReviewCollectionModalProps) {
  const {
    formState: { isSubmitting },
    handleSubmit,
    control,
    register,
  } = useForm<ReviewCollectionFormValues>({
    defaultValues: {
      reviewStatus: collection.reviewStatus,
      humanAnalysis: collection.humanAnalysis,
      aiAnalysis: collection.aiAnalysis,
      reviewMessage: collection.reviewMessage,
      revalidate: collection.revalidate,
      verified: collection.verified,
    },
  });

  const { enqueueSnackbar: notify } = useSnackbar();

  const onApply = async (values: ReviewCollectionFormValues) => {
    try {
      await reviewCollection(collection.uuid, {
        ...values,
        reviewMessage: values.reviewMessage || '-',
      });
      notify('Collection reviewed', { variant: 'success' });
      onClose();
    } catch (e) {
      notify(getMessageFromError(e), { variant: 'error' });
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Card sx={{ width: '400px' }}>
        <CardHeader title="Review Collection" />
        <CardContent>
          <form onSubmit={handleSubmit(onApply)}>
            <Stack spacing={2} justifyContent="center" mb={2}>
              <FormControl>
                <FormLabel>Review Status</FormLabel>
                <Controller
                  control={control}
                  name="reviewStatus"
                  rules={{ required: true }}
                  render={({ field }) => (
                    <RadioGroup row {...field}>
                      {reviewStatusOptions.map((option) => (
                        <FormControlLabel
                          key={option.value}
                          value={option.value}
                          control={<Radio />}
                          label={option.label}
                        />
                      ))}
                    </RadioGroup>
                  )}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Human Analysis</FormLabel>
                <Controller
                  control={control}
                  name="humanAnalysis"
                  rules={{ required: true }}
                  render={({ field }) => (
                    <RadioGroup row {...field}>
                      {humanAnalysisOptions.map((option) => (
                        <FormControlLabel
                          key={option.value}
                          value={option.value}
                          control={<Radio />}
                          label={option.label}
                        />
                      ))}
                    </RadioGroup>
                  )}
                />
              </FormControl>
              <FormControl>
                <FormLabel>A.I. Analysis</FormLabel>
                <Controller
                  control={control}
                  name="aiAnalysis"
                  rules={{ required: true }}
                  render={({ field }) => (
                    <RadioGroup row {...field}>
                      {aiAnalysisOptions.map((option) => (
                        <FormControlLabel
                          key={option.value}
                          value={option.value}
                          control={<Radio />}
                          label={option.label}
                        />
                      ))}
                    </RadioGroup>
                  )}
                />
              </FormControl>
              <Stack direction="row" justifyContent="space-between">
                Revalidate:
                <Controller
                  control={control}
                  name="revalidate"
                  render={({ field }) => (
                    <Switch checked={field.value} {...field} />
                  )}
                />
              </Stack>
              <Stack direction="row" justifyContent="space-between">
                Verified:
                <Controller
                  control={control}
                  name="verified"
                  render={({ field }) => (
                    <Switch checked={field.value} {...field} />
                  )}
                />
              </Stack>
              <TextField
                label="Review Message"
                multiline
                {...register('reviewMessage')}
              />
            </Stack>
            <Stack direction="row" spacing={2} justifyContent="space-between">
              <Button color="error" onClick={onClose}>
                Cancel
              </Button>
              <Button
                loading={isSubmitting}
                type="submit"
                color="secondary"
                variant="contained"
              >
                Apply
              </Button>
            </Stack>
          </form>
        </CardContent>
      </Card>
    </Modal>
  );
}

type ReviewStatusOptions = {
  label: string;
  value: CollectionReview['reviewStatus'];
};
const reviewStatusOptions: ReviewStatusOptions[] = [
  { value: 'rejected', label: 'Rejected' },
  { value: 'approved', label: 'Approved' },
  { value: 'reviewing', label: 'Reviewing' },
  { value: 'awaiting_review', label: 'Awaiting Review' },
];

type HumanAnalysisOptions = {
  label: string;
  value: CollectionReview['humanAnalysis'];
};
const humanAnalysisOptions: HumanAnalysisOptions[] = [
  { value: 'everywhere', label: 'Everywhere' },
  { value: 'internal', label: 'Internal' },
  { value: 'public_profile', label: 'Public Profile' },
  { value: 'private_profile', label: 'Private Profile' },
  { value: 'not_set', label: 'Not Set' },
];

type AiAnalysisOptions = {
  label: string;
  value: CollectionReview['aiAnalysis'];
};
const aiAnalysisOptions: AiAnalysisOptions[] = [
  { value: 'approved', label: 'Approved' },
  { value: 'review', label: 'Review' },
  { value: 'denied', label: 'Denied' },
  { value: 'not_set', label: 'Not Set' },
];
