import { Add } from '@mui/icons-material';
import {
  Autocomplete,
  Button,
  Card,
  CardContent,
  InputAdornment,
  TextField,
} from '@mui/material';
import { debounce } from 'lodash';
import { useCallback } from 'react';
import { UserClubRelations } from 'src/components/modules/Profile/sections/Clubs/types';
import Flex from 'src/components/shared/Flex';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';
import HeaderBreadcrumbs from 'src/template/components/HeaderBreadcrumbs';
import Iconify from 'src/template/components/Iconify';
import ClubsTable from '../../components/Table';
import useClubsView from './useView';

const searchOptions = [
  {
    label: 'Name',
    query: 'name',
  },
  {
    label: 'Uuid',
    query: 'uuid',
  },
];
interface ClubsProps {
  userUuid?: string;
  relation?: UserClubRelations;
  showHeader?: boolean;
}

export default function Clubs({
  showHeader = true,
  userUuid,
  relation,
}: ClubsProps): JSX.Element {
  const {
    data,
    isLoading,
    params,
    handleOpenCreateModal,
    handleOpenUpdateModal,
    handleOpenDeleteModal,
    handleOpenAddAssetModal,
    handleOpenAssetsListModal,
  } = useClubsView({ userUuid, relation });

  const { addParam, removeParam } = useURLSearchParams();

  const handleChangeSearch = useCallback(
    (value: string, param: string = 'query') => {
      if (!value) removeParam(param);
      else addParam(param, value);
    },
    [removeParam, addParam]
  );

  const debouncedSearch = useCallback(debounce(handleChangeSearch, 300), [
    debounce,
    handleChangeSearch,
  ]);

  return (
    <>
      {showHeader && (
        <HeaderBreadcrumbs
          heading="Clubs"
          links={[{ name: 'Clubs' }]}
          action={
            <Button
              variant="contained"
              color="secondary"
              onClick={handleOpenCreateModal}
              startIcon={<Add />}
            >
              Create Club
            </Button>
          }
        />
      )}
      <Card>
        <Flex gap={2} p={2}>
          <Autocomplete
            options={searchOptions}
            fullWidth
            renderInput={(renderInputParams) => (
              <TextField label="Search By" {...renderInputParams} />
            )}
            getOptionLabel={(p) => p.label}
            sx={{ maxWidth: 250 }}
            defaultValue={searchOptions.find(
              (o) => o.query === params.searchBy
            )}
            key={params.searchBy}
            onChange={(_, value) => {
              if (!value) removeParam('searchBy');
              else addParam('searchBy', value.query);
            }}
          />
          <TextField
            fullWidth
            label="Search"
            defaultValue={params.query}
            type="search"
            onChange={(e) => {
              const { value } = e.target;
              debouncedSearch(value);
            }}
            key={params.query}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify
                    icon="eva:search-fill"
                    sx={{ color: 'text.disabled', width: 20, height: 20 }}
                  />
                </InputAdornment>
              ),
            }}
          />
        </Flex>

        <CardContent>
          <ClubsTable
            data={data?.body ?? []}
            totalCount={data?.meta.totalCount ?? 0}
            isLoading={isLoading}
            params={params}
            handleOpenUpdateModal={handleOpenUpdateModal}
            handleOpenDeleteModal={handleOpenDeleteModal}
            handleOpenAddAssetModal={handleOpenAddAssetModal}
            handleOpenAssetsListModal={handleOpenAssetsListModal}
          />
        </CardContent>
      </Card>
    </>
  );
}
