import { Divider, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import { ReactNode, useCallback, useState } from 'react';
import NumericTypography from '../NumericTypography';

export type SimpleTableData = {
  id: string;
  label: ReactNode | string;
  value: ReactNode | string | number;
  onClick?: () => void;
  children?: ReactNode;
  tooltip?: string;
};

type SimpleTableProps = {
  data: SimpleTableData[];
  rounded?: boolean;
  border?: boolean;
};

export default function SimpleTable({
  data,
  rounded = false,
  border = false,
}: SimpleTableProps) {
  const theme = useTheme();

  return (
    <Stack
      width="100%"
      border={border ? `1px solid ${theme.palette.primary.main}1A` : ''}
      borderRadius={rounded ? '7px' : '0'}
    >
      {data &&
        data.map((item) =>
          item.tooltip ? (
            <Tooltip title={item.tooltip}>
              <SimpleTableRow item={item} key={item.id} rounded={rounded} />
            </Tooltip>
          ) : (
            <SimpleTableRow item={item} key={item.id} rounded={rounded} />
          )
        )}
    </Stack>
  );
}

type SimpleTableRowProps = {
  item: SimpleTableData;
  rounded?: boolean;
};

function SimpleTableRow({ item, rounded }: SimpleTableRowProps) {
  const theme = useTheme();

  const [expandedRowKey, setExpandedRowKey] = useState<string | null>(null);

  const toggleRowExpansion = useCallback(
    (newKey: string) => {
      setExpandedRowKey(newKey === expandedRowKey ? null : newKey);
    },
    [expandedRowKey]
  );

  return (
    <Stack
      key={item.id}
      sx={{
        ':nth-child(even)': {
          backgroundColor: `${theme.palette.primary.main}13`,
        },
        ...(rounded
          ? {
              ':first-child': {
                borderTopLeftRadius: '7px',
                borderTopRightRadius: '7px',
              },
              ':last-child': {
                borderBottomLeftRadius: '7px',
                borderBottomRightRadius: '7px',
              },
            }
          : {}),
      }}
    >
      <Stack
        alignItems="center"
        justifyContent="space-between"
        direction="row"
        py={1}
        px={2}
        onClick={() => {
          if (item.children) toggleRowExpansion(item.id);
          if (item.onClick) item.onClick();
        }}
        sx={{
          ...(item.onClick || item.children
            ? {
                ':hover': {
                  backgroundColor: `${theme.palette.primary.main}10`,
                  cursor: 'pointer',
                },
              }
            : {}),
        }}
      >
        {typeof item.label === 'string' ? (
          <Typography
            fontSize="14px"
            sx={{
              textTransform: 'capitalize',
            }}
          >
            {item.label}
          </Typography>
        ) : (
          item.label
        )}
        {typeof item.value === 'string' || typeof item.value === 'number' ? (
          <NumericTypography fontSize="14px" fontWeight="bold">
            {item.value}
          </NumericTypography>
        ) : (
          item.value
        )}
      </Stack>
      {item.children && expandedRowKey === item.id ? (
        <Stack
          bgcolor={theme.palette.background.default}
          direction="row"
          pl={1}
          justifyContent="space-between"
        >
          <Divider orientation="vertical" flexItem />
          {item.children}
        </Stack>
      ) : null}
    </Stack>
  );
}
