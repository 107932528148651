import { NFT } from 'src/lib/types/nft';
import { getImageURL } from 'src/lib/utils/nft';
import Routes from 'src/routes/Routes';
import Image from 'src/template/components/Image';
import Link from '../Link';

type Props = {
  nft: Pick<NFT, 'fileUrl' | 'name' | 'uuid'>;
};
export default function MagicTableNFTCell({ nft }: Props): JSX.Element {
  const { name, fileUrl, uuid } = nft;
  return (
    <>
      <Image
        alt={name ?? 'nft image'}
        src={getImageURL(fileUrl) ?? ''}
        sx={{ mr: 2, width: 40, aspectRatio: 1, borderRadius: 0.5 }}
      />

      <Link to={Routes.nft(uuid)} color="inherit" underline="hover">
        {name}
      </Link>
    </>
  );
}
