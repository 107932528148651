/* eslint-disable no-promise-executor-return */
import { Delete, Edit, Photo } from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  InputAdornment,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { GridSortDirection } from '@mui/x-data-grid';
import { debounce } from 'lodash';

import Flex from 'src/components/shared/Flex';
import Link from 'src/components/shared/Link';
import MagicTable from 'src/components/shared/MagicTable/MagicTable';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';

import { LabelValue } from 'src/lib/types/labelValue';
import Iconify from 'src/template/components/Iconify';
import Image from 'src/template/components/Image';

import { GameCategory } from 'src/lib/types/gameCategory';

import { formatUTCDate } from 'src/lib/utils/formatUTCDate';
import { FetchGamesQueryParams } from '../../view/List/useView';

type GameCategoryTableProps = {
  data?: GameCategory[];
  isLoading: boolean;
  params: FetchGamesQueryParams;
  handleOpenDeleteModal: (uuid: string) => void;
  onEditClick: (uuid: string) => void;
};

export default function GameCategoryTable({
  data,
  isLoading,
  params: { page, size, searchBy, orderBy, sortOrder },
  handleOpenDeleteModal,
  onEditClick,
}: GameCategoryTableProps) {
  const { addParam, removeParam, allSearchParams } = useURLSearchParams();

  function handleChangeSearch(value: string, param: string = 'query') {
    if (!value) removeParam(param);
    else addParam(param, value);
  }

  const debouncedSearch = debounce(handleChangeSearch, 1000);

  return (
    <MagicTable.Container>
      <Flex gap={2} p={2} justifyContent={'flex-end'}>
        <Autocomplete
          options={SEARCH_OPTIONS}
          fullWidth
          renderInput={(params) => <TextField label="Search By" {...params} />}
          getOptionLabel={(p) => p.label}
          sx={{ maxWidth: 250 }}
          defaultValue={SEARCH_OPTIONS.find((o) => o.value === searchBy)}
          key={searchBy}
          onChange={(_, value) => {
            if (!value) removeParam('searchBy');
            else addParam('searchBy', value.value);
          }}
        />
        <TextField
          fullWidth
          label="Search"
          defaultValue={allSearchParams.query}
          type="search"
          onChange={(e) => {
            const { value } = e.target;
            debouncedSearch(value);
          }}
          key={allSearchParams.query}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Iconify
                  icon="eva:search-fill"
                  sx={{ color: 'text.disabled', width: 20, height: 20 }}
                />
              </InputAdornment>
            ),
          }}
        />
      </Flex>
      <Box px={2}>
        <MagicTable
          loading={isLoading}
          pageSize={size}
          page={page}
          paginationMode="server"
          onPageChange={(newPage) => {
            addParam('page', newPage);
          }}
          onPageSizeChange={(newSize) => {
            addParam('size', newSize);
          }}
          sortModel={[
            {
              field: orderBy,
              sort: sortOrder as GridSortDirection,
            },
          ]}
          rows={data ?? []}
          hideNumberOfRows
          experimentalFeatures={{ newEditingApi: true }}
          columns={[
            {
              field: 'imageUrl',
              headerName: 'Image',
              flex: 1 / 3,
              renderCell({ row }: { row: GameCategory }) {
                if (row.imageUrl)
                  return (
                    <Link to={row.imageUrl} target="_blank">
                      <Flex gap={2} alignItems="center">
                        <Image
                          src={row.imageUrl}
                          alt={row.name}
                          sx={{ width: 30, height: 30, borderRadius: 1 }}
                        />
                      </Flex>
                    </Link>
                  );

                return (
                  <Flex gap={2} alignItems="center">
                    <Photo sx={{ color: 'primary.main' }} />
                    <Typography variant="body1" color="text.primary" />
                  </Flex>
                );
              },
            },
            {
              field: 'name',
              headerName: 'Name',
              flex: 1 / 2,
              renderCell({ row }: { row: GameCategory }) {
                return (
                  <Typography variant="body2" color="text.secondary">
                    {row.name || '--'}
                  </Typography>
                );
              },
            },
            {
              field: 'description',
              headerName: 'Description',
              flex: 1,
              renderCell({ row }: { row: GameCategory }) {
                return (
                  <Typography variant="body2" color="text.secondary">
                    {row.description || '--'}
                  </Typography>
                );
              },
            },
            {
              field: 'createdAt',
              headerName: 'Created At',
              flex: 1 / 2,
              renderCell({ row }: { row: GameCategory }) {
                return (
                  <Typography variant="body2" color="text.secondary">
                    {formatUTCDate(row.createdAt)}
                  </Typography>
                );
              },
            },
            {
              field: 'updatedAt',
              headerName: 'Updated At',
              flex: 1 / 2,
              renderCell({ row }: { row: GameCategory }) {
                return (
                  <Typography variant="body2" color="text.secondary">
                    {row.updatedAt ? formatUTCDate(row.updatedAt) : '--'}
                  </Typography>
                );
              },
            },
            {
              field: 'deletedAt',
              headerName: 'Deleted At',
              flex: 1 / 2,
              renderCell({ row }: { row: GameCategory }) {
                return (
                  <Typography variant="body2" color="text.secondary">
                    {row.deletedAt ? formatUTCDate(row.deletedAt) : '--'}
                  </Typography>
                );
              },
            },
            {
              field: 'actions',
              headerName: '',
              align: 'center',
              sortable: false,
              flex: 0,
              renderCell({ row }) {
                return (
                  <Stack direction="row" spacing={1}>
                    <Tooltip title="Update GameCategory " arrow placement="top">
                      <Edit
                        color="primary"
                        sx={{ cursor: 'pointer' }}
                        onClick={() => onEditClick(row.uuid)}
                      />
                    </Tooltip>
                    <Tooltip title="Delete GameCategory " arrow placement="top">
                      <Delete
                        color="error"
                        sx={{ cursor: 'pointer' }}
                        onClick={() => handleOpenDeleteModal(row.uuid)}
                      />
                    </Tooltip>
                  </Stack>
                );
              },
            },
          ]}
        />
      </Box>
    </MagicTable.Container>
  );
}

const SEARCH_OPTIONS: LabelValue[] = [
  {
    label: ' Name',
    value: 'name',
  },
];
