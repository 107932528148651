import { Card, CardContent, CardHeader, Stack } from '@mui/material';
import { capitalCase } from 'change-case';
import { Controller } from 'react-hook-form';
import Button from 'src/components/shared/Buttons/CustomButton';
import { ObjectLinkComponent } from 'src/components/shared/Form/useObjectLinkSearch';
import ControllerField from 'src/components/shared/Inputs/ControllerField';
import Modal from 'src/components/shared/Modal';
import SelectOption from 'src/components/shared/SelectOption';
import UploadContent from 'src/components/shared/UploadContent';
import { ChannelType } from 'src/lib/types/channel';
import { CreateChannelFormValues } from './types';
import useCreateChannelFormView from './useView';

type Props = {
  defaultValues: CreateChannelFormValues;
  onSubmit: (values: CreateChannelFormValues) => Promise<void>;
  title: string;
};

export default function CreateChannelForm({
  defaultValues,
  title,
  onSubmit,
}: Props): JSX.Element {
  const {
    form: {
      control,
      handleSubmit,
      formState: { isSubmitting },
      watch,
      setValue,
    },
    handleCloseModal,
    channelType,
    onCreateImage,
    isUpdatingBackgroundImage,
    objectLinkProps,
    UserSearchComponent,
    userSearchProps,
  } = useCreateChannelFormView({ defaultValues });

  return (
    <Modal open onClose={handleCloseModal}>
      <Card
        sx={{
          width: '700px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <CardHeader title={title} />
        <CardContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack>
              <Stack mb={2} spacing={1.5}>
                <ControllerField
                  control={control}
                  name="type"
                  fullWidth
                  label="Channel Type"
                  select
                  wrapperProps={{
                    sx: {
                      gridColumn: '1 / span 2',
                    },
                  }}
                >
                  <SelectOption value={ChannelType.PERSONAL}>
                    {capitalCase(ChannelType.PERSONAL)}
                  </SelectOption>
                  {/* objectEntries(ChannelType).map(([label, value]) => (
                    <SelectOption value={value} key={value}>
                      {capitalCase(label)}
                    </SelectOption>
                  )) */}
                </ControllerField>

                {channelType == ChannelType.SECONDARY && (
                  <>
                    <ControllerField
                      control={control}
                      name="name"
                      label="Name"
                      fullWidth
                    />
                    <ControllerField
                      control={control}
                      name="description"
                      label="Description"
                      multiline
                      minRows={3}
                      fullWidth
                    />
                    <Controller
                      name="backgroundImageUrl"
                      control={control}
                      render={({ field }) => (
                        <UploadContent
                          {...field}
                          type="image"
                          content={field.value ?? ''}
                          onEdit={(file) => {
                            onCreateImage(file);
                          }}
                          onRemove={() => {
                            setValue('backgroundImageUrl', '');
                          }}
                          showEditButton
                          sx={{
                            maxWidth: '300px',
                            maxHeight: '300px',
                            minHeight: '30px',
                            minWidth: '100px',
                          }}
                          isLoading={isUpdatingBackgroundImage}
                        />
                      )}
                    />
                  </>
                )}

                {channelType == ChannelType.PERSONAL && (
                  <UserSearchComponent {...userSearchProps} />
                )}

                <ObjectLinkComponent {...objectLinkProps} />
              </Stack>
              <Stack
                direction="row"
                justifyContent="space-between"
                spacing={1}
                mt={2}
              >
                <Button color="error" onClick={handleCloseModal} size="large">
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  type="submit"
                  loading={isSubmitting}
                  size="large"
                >
                  Save
                </Button>
              </Stack>
            </Stack>
          </form>
        </CardContent>
      </Card>
    </Modal>
  );
}
