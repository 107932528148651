import { Card, CardContent, Stack, Tab, Tabs } from '@mui/material';
import { capitalCase } from 'change-case';
import { Suspense } from 'react';
import { Navigate, useParams } from 'react-router';
import Flex from 'src/components/shared/Flex';
import Loader from 'src/components/shared/Loader';
import Routes from 'src/routes/Routes';
import HeaderBreadcrumbs from 'src/template/components/HeaderBreadcrumbs';
import LoadingScreen from 'src/template/components/LoadingScreen';
import CLUB_TABS from './clubTabs';
import useClubDetails from './useView';

export default function ClubDetails() {
  const { clubUuid: uuid } = useParams<'clubUuid'>();
  if (!uuid) return <Navigate to={Routes.comunity.clubs} replace />;

  const { club, isLoading, currentTab, onChangeTab } = useClubDetails();

  return club && !isLoading ? (
    <>
      <HeaderBreadcrumbs
        heading={club.name || 'Empty Name'}
        links={[
          { name: 'Clubs', href: Routes.comunity.clubs },
          { name: club.name || 'Empty Name' },
        ]}
      />
      <Card>
        <Stack pb={2}>
          <Flex sx={{ px: 2, bgcolor: 'background.neutral' }} mb={2}>
            <Tabs
              allowScrollButtonsMobile
              variant="scrollable"
              scrollButtons="auto"
              value={currentTab}
              onChange={onChangeTab}
            >
              {CLUB_TABS.map((tab) => (
                <Tab
                  disableRipple
                  key={tab.value}
                  value={tab.value}
                  icon={tab.icon}
                  label={capitalCase(tab.value)}
                />
              ))}
            </Tabs>
          </Flex>
          <CardContent>
            <Suspense fallback={<Loader />}>
              {CLUB_TABS.map(
                (tab) => tab.value === currentTab && tab.component()
              )}
            </Suspense>
          </CardContent>
        </Stack>
      </Card>
    </>
  ) : (
    <LoadingScreen />
  );
}
