import { Stack } from '@mui/material';
import { GridAlignment } from '@mui/x-data-grid';
import { capitalCase } from 'change-case';
import {
  OBJECT_MODERATION_REVIEW_STATUS,
  ObjectVisibility,
  WithObjectModerationProps,
} from 'src/lib/types/objectModeration';
import Label from 'src/template/components/Label';
import { colorByObjectVisibility, colorByReviewStatus } from '../utils/utils';

export const reviewStatusColumn = {
  align: 'center' as GridAlignment,
  sotable: false,
  field: 'reviewStatus',
  headerName: 'Status / Visibility',
  renderCell: ({ row }: { row: WithObjectModerationProps<{}> }) => (
    <Stack alignItems="center" spacing={1}>
      {row.visibility && (
        <Label
          variant="ghost"
          color={
            colorByReviewStatus[
              row.reviewStatus as OBJECT_MODERATION_REVIEW_STATUS
            ]
          }
          sx={{ textTransform: 'capitalize' }}
        >
          {capitalCase(row.reviewStatus ?? 'none')}
        </Label>
      )}
      {row.visibility && (
        <Label
          variant="filled"
          color={colorByObjectVisibility[row.visibility as ObjectVisibility]}
        >
          {capitalCase(row.visibility ?? 'none')}
        </Label>
      )}
    </Stack>
  ),
};
