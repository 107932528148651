import formatStickyCoinValue from 'src/lib/utils/formatStickyCoinValue';
import useSWR, { SWRResponse } from 'swr';
import { UserNftActivityStats } from '../sections/Activity/types';

function parseData(
  data: UserNftActivityStats | undefined
): UserNftActivityStats | undefined {
  if (!data) return undefined;
  const { purchases, sales, transfers } = data;
  return {
    purchases: {
      ...purchases,
      amount: {
        nonSuspicious: formatStickyCoinValue(
          purchases.amount?.nonSuspicious ?? 0
        ),
        suspicious: formatStickyCoinValue(purchases.amount?.suspicious ?? 0),
      },
    },
    sales: {
      ...sales,
      amount: {
        nonSuspicious: formatStickyCoinValue(sales.amount?.nonSuspicious ?? 0),
        suspicious: formatStickyCoinValue(sales.amount?.suspicious ?? 0),
      },
    },
    transfers,
  };
}
export const fetchSuspiciousActivity = (
  userUuid: string
): SWRResponse<UserNftActivityStats> => {
  const { data, ...response } = useSWR(
    `admin/users/${userUuid}/activity/suspicious-transactions-report`
  );

  return {
    ...response,
    data: parseData(data),
  };
};
