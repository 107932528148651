import { User } from 'src/lib/types/users';
import { getMessageFromError } from 'src/lib/utils/error';
import { parseSearchParams } from 'src/lib/utils/parseSearchParams';
import useSWR, { SWRConfiguration, SWRResponse } from 'swr';

type Response = {
  meta: {
    totalCount: number;
  };
  data: User[];
};
export default function fetchUserFollowers(
  userId: string,
  params?: Partial<PossibleParams>,
  options?: SWRConfiguration
): SWRResponse<Response> {
  const parsedParams = parseSearchParams(params ?? {});
  const { error, ...response } = useSWR(
    `userFollows/followers/${userId}?${parsedParams}`,
    options
  );

  return { ...response, error: error ? getMessageFromError(error) : undefined };
}

type PossibleParams = {
  page: number | string;
  size: number | string;
};
