import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import {
  Autocomplete,
  Box,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { capitalCase } from 'change-case';
import { Controller } from 'react-hook-form';
import AsyncAutoComplete from 'src/components/shared/AsyncAutoComplete';
import Button from 'src/components/shared/Buttons/CustomButton';
import EditableImage from 'src/components/shared/EditableImage';
import Flex from 'src/components/shared/Flex';
import { ObjectLinkComponent } from 'src/components/shared/Form/useObjectLinkSearch';
import ControllerField from 'src/components/shared/Inputs/ControllerField';
import Modal from 'src/components/shared/Modal';
import SelectOption from 'src/components/shared/SelectOption';
import {
  AgeRestrictions,
  GameOrientations,
  GamePublishRights,
} from 'src/lib/types/game';
import { AdminListGamesRow } from 'src/lib/types/gameRow';
import objectEntries from 'src/lib/utils/objectEntries';
import { GameFormValues } from './types';
import useGameFormView from './useView';

type Props = {
  defaultValues: GameFormValues;
  onSubmit: (values: GameFormValues) => Promise<void>;
  title: string;
  game?: AdminListGamesRow;
};

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function GameForm({
  defaultValues,
  title,
  onSubmit,
  game,
}: Props): JSX.Element {
  const {
    form: { control, handleSubmit, setValue, getFieldState },
    isSubmitting,
    handleCloseModal,
    currentStep,
    lastStep,
    handleNextStep,
    handlePreviousStep,
    imageUrl,
    onUpdateImage,
    gamePublishers,
    gameCategories,
    tags,
    gameModes: modes,
    objectLinkProps,
    isMobile,
    hasAds,
  } = useGameFormView({ defaultValues, game });

  return (
    <Modal open onClose={handleCloseModal}>
      <Card
        sx={{
          width: '600px',
        }}
      >
        <CardHeader title={title} />
        <CardContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack>
              <Stack mb={2} spacing={1.5}>
                {currentStep === 1 && (
                  <Stack mb={2} spacing={1.5}>
                    <ControllerField
                      control={control}
                      name="game.name"
                      label="Name"
                      fullWidth
                    />
                    <ControllerField
                      control={control}
                      name="game.description"
                      label="Description"
                      fullWidth
                      multiline
                      rows={4}
                    />
                    <Controller
                      control={control}
                      name="game.gamePublisher"
                      render={({ field }) => (
                        <Autocomplete
                          freeSolo
                          handleHomeEndKeys
                          options={
                            gamePublishers?.map(
                              (gamePublisher) => gamePublisher.name
                            ) ?? []
                          }
                          value={field.value}
                          fullWidth
                          renderInput={(params) => (
                            <TextField
                              label="Publisher"
                              {...params}
                              {...field}
                              error={Boolean(
                                getFieldState('game.gamePublisher')?.error
                              )}
                              helperText={
                                getFieldState('game.gamePublisher')?.error
                                  ?.message
                              }
                            />
                          )}
                          onSelect={(e) => {
                            field.onChange(e);
                          }}
                        />
                      )}
                    />
                    <ControllerField
                      control={control}
                      name="game.developerName"
                      label="Developer Name"
                      fullWidth
                    />
                    <Controller
                      name="game.gameCategoryUuid"
                      control={control}
                      render={({ field }) => (
                        <AsyncAutoComplete
                          id="gameCategory"
                          label="Game Category"
                          options={
                            gameCategories.options || [{ label: '', uuid: '' }]
                          }
                          isLoading={gameCategories.isLoading}
                          disabled={gameCategories.disabled}
                          handleInputChange={gameCategories.onInputChange}
                          handleChange={(event, value) =>
                            setValue('game.gameCategoryUuid', value?.uuid || '')
                          }
                          defaultValue={gameCategories.defaultValue}
                          {...field}
                        />
                      )}
                    />
                    <Controller
                      name="game.gameTagsNames"
                      control={control}
                      render={({ field }) => (
                        <Autocomplete
                          multiple
                          id="checkboxes-tags"
                          options={tags.options || []}
                          disableCloseOnSelect
                          getOptionLabel={(option) => option.label}
                          renderOption={(props, option, { selected }) => (
                            <li {...props}>
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              {option.label}
                            </li>
                          )}
                          style={{ width: '100%' }}
                          onChange={(e, value) => {
                            setValue(
                              'game.gameTagsNames',
                              value.map((v) => v.label)
                            );
                          }}
                          onInputChange={tags.onInputChange}
                          filterOptions={(x) => x}
                          loading={tags.isLoading}
                          defaultValue={tags.defaultValue}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Tags"
                              placeholder="Select Tags"
                              {...field}
                            />
                          )}
                        />
                      )}
                    />
                    <ObjectLinkComponent {...objectLinkProps} />
                    <ControllerField
                      control={control}
                      name="game.secondaryLink"
                      label="Secondary Link"
                      fullWidth
                    />

                    <ControllerField
                      control={control}
                      name="game.orientation"
                      label="Orientation"
                      fullWidth
                      select
                      wrapperProps={{
                        sx: {
                          gridColumn: '1 / span 2',
                        },
                      }}
                    >
                      {objectEntries(GameOrientations).map(([label, value]) => (
                        <SelectOption value={value} key={value}>
                          {capitalCase(label)}
                        </SelectOption>
                      ))}
                    </ControllerField>

                    <ControllerField
                      control={control}
                      name="game.publishRightsType"
                      label="Publish Rights Type"
                      fullWidth
                      select
                      wrapperProps={{
                        sx: {
                          gridColumn: '1 / span 2',
                        },
                      }}
                    >
                      {objectEntries(GamePublishRights).map(
                        ([label, value]) => (
                          <SelectOption value={value} key={value}>
                            {capitalCase(label)}
                          </SelectOption>
                        )
                      )}
                    </ControllerField>
                    <Box p={1}>
                      <FormLabel id="demo-row-radio-buttons-group-label">
                        Is Mobile
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={isMobile ? 'true' : 'false'}
                        onChange={(e) => {
                          if (e.target.value === 'true') {
                            setValue('game.isMobile', true);
                          } else {
                            setValue('game.isMobile', false);
                          }
                        }}
                      >
                        <FormControlLabel
                          value="true"
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="false"
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    </Box>
                  </Stack>
                )}
                {currentStep === 2 && (
                  <Stack mb={2} spacing={1.5}>
                    <ControllerField
                      control={control}
                      name="version.version"
                      label="Version"
                      fullWidth
                    />

                    <ControllerField
                      control={control}
                      name="version.ageRestriction"
                      label="Age Restriction"
                      fullWidth
                      select
                      wrapperProps={{
                        sx: {
                          gridColumn: '1 / span 2',
                        },
                      }}
                    >
                      {objectEntries(AgeRestrictions).map(([label, value]) => (
                        <SelectOption value={value} key={label}>
                          {`${value}+`}
                        </SelectOption>
                      ))}
                    </ControllerField>

                    <Controller
                      name="version.gameModesUuids"
                      control={control}
                      render={({ field }) => (
                        <Autocomplete
                          multiple
                          id="checkboxes-modes"
                          options={modes.options || []}
                          disableCloseOnSelect
                          getOptionLabel={(option) => option.label}
                          renderOption={(props, option, { selected }) => (
                            <li {...props}>
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                              />
                              {option.label}
                            </li>
                          )}
                          style={{ width: '100%' }}
                          onChange={(e, value) => {
                            setValue(
                              'version.gameModesUuids',
                              value.map((v) => v.uuid)
                            );
                          }}
                          onInputChange={modes.onInputChange}
                          filterOptions={(x) => x}
                          loading={modes.isLoading}
                          defaultValue={modes.defaultValue}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Game Modes"
                              placeholder="Select Game Modes"
                              {...field}
                            />
                          )}
                        />
                      )}
                    />

                    <ControllerField
                      control={control}
                      name="version.url"
                      label="Url"
                      fullWidth
                    />

                    <Box p={1}>
                      <FormLabel id="demo-row-radio-buttons-group-label">
                        Has Ads
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={hasAds ? 'true' : 'false'}
                        onChange={(e) => {
                          if (e.target.value === 'true') {
                            setValue('version.hasAds', true);
                          } else {
                            setValue('version.hasAds', false);
                          }
                        }}
                      >
                        <FormControlLabel
                          value="true"
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="false"
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    </Box>

                    <ControllerField
                      control={control}
                      name="version.additionalComments"
                      label="Additional Comments"
                      fullWidth
                      multiline
                      rows={4}
                    />

                    <Flex justifyContent={'space-between'}>
                      <Typography variant="subtitle1">
                        {`Upload a image:`}
                      </Typography>
                      <EditableImage
                        image={imageUrl ?? ''}
                        onEdit={(file) => {
                          onUpdateImage(file);
                        }}
                        imageProps={{
                          alt: `${defaultValues.version.version} image`,
                          minWidth: '100px',
                          minHeight: '30px',
                        }}
                        showEditButton
                        sx={{
                          maxWidth: '300px',
                          maxHeight: '300px',
                          minHeight: '30px',
                          minWidth: '100px',
                        }}
                      />
                    </Flex>
                  </Stack>
                )}
              </Stack>
              <Stack
                direction="row"
                justifyContent="space-between"
                spacing={1}
                mt={2}
              >
                {currentStep === 1 ? (
                  <Button color="error" onClick={handleCloseModal} size="large">
                    Cancel
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="error"
                    type="button"
                    onClick={handlePreviousStep}
                    size="large"
                  >
                    Previous
                  </Button>
                )}
                {currentStep === lastStep ? (
                  <Button
                    variant="contained"
                    color="secondary"
                    type="submit"
                    loading={isSubmitting}
                    size="large"
                  >
                    Save
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="secondary"
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();

                      handleNextStep();
                    }}
                    size="large"
                  >
                    Next
                  </Button>
                )}
              </Stack>
            </Stack>
          </form>
        </CardContent>
      </Card>
    </Modal>
  );
}
