import {
  Add,
  ContentCopy,
  Delete,
  Edit,
  RemoveRedEye,
} from '@mui/icons-material';
import { MenuItem } from '@mui/material';
import { GridSortDirection } from '@mui/x-data-grid';
import { capitalCase } from 'change-case';
import CopyToClipboard from 'react-copy-to-clipboard';
import Link from 'src/components/shared/Link';
import MagicTable from 'src/components/shared/MagicTable/MagicTable';
import MagicTableUserCell from 'src/components/shared/MagicTable/UserCell';
import MoreMenu from 'src/components/shared/MoreMenu';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';
import { ExternalClubAsset } from 'src/lib/types/club';
import { ClubWithObjectLinks } from 'src/lib/types/clubWithAdminAnalytics';
import { formatUTCDate } from 'src/lib/utils/formatUTCDate';
import Routes from 'src/routes/Routes';
import { FetchClubsQueryParams } from '../../view/List/useView';

interface ClubsTableProps {
  data: ClubWithObjectLinks[];
  totalCount?: number;
  isLoading: boolean;
  params: FetchClubsQueryParams;
  handleOpenUpdateModal: (club: ClubWithObjectLinks) => void;
  handleOpenDeleteModal: (uuid: string) => void;
  handleOpenAddAssetModal: (uuid: string) => void;
  handleOpenAssetsListModal: (
    chats: ExternalClubAsset[],
    topics: ExternalClubAsset[]
  ) => void;
}

export default function ClubsTable({
  data,
  totalCount,
  isLoading,
  params,
  handleOpenUpdateModal,
  handleOpenDeleteModal,
  handleOpenAddAssetModal,
  handleOpenAssetsListModal,
}: ClubsTableProps) {
  const { addParam, removeParam } = useURLSearchParams();

  return (
    <MagicTable
      rows={data}
      getRowId={(r) => r.uuid}
      loading={isLoading}
      rowCount={totalCount}
      paginationMode="server"
      pageSize={params.size}
      page={params.page}
      onPageChange={(newPage) => {
        addParam('page', newPage);
      }}
      onPageSizeChange={(newSize) => {
        addParam('size', newSize);
      }}
      sortModel={[
        {
          field: params.orderBy,
          sort: params.sortOrder as GridSortDirection,
        },
      ]}
      onSortModelChange={(model) => {
        const currentModel = model[0];
        if (currentModel) {
          addParam('orderBy', currentModel.field);
          if (currentModel.sort) addParam('order', currentModel.sort);
        } else {
          removeParam('order');
          removeParam('orderBy');
        }
      }}
      columns={[
        {
          field: 'name',
          headerName: 'Name',
          sortable: true,
          renderCell({ row }) {
            return (
              <Link
                to={`${Routes.comunity.club(row.uuid ?? '')}`}
                color={'GrayText'}
                underline="hover"
              >
                {row.name}
              </Link>
            );
          },
        },
        {
          field: 'createdByUser',
          headerName: 'Creator',
          sortable: false,
          renderCell({ row }) {
            const owner = row.createdByUser;
            return owner ? <MagicTableUserCell user={owner} /> : null;
          },
        },
        {
          field: 'description',
          headerName: 'Description',
          sortable: false,
        },
        {
          field: 'joinMethod',
          headerName: 'Join Method',
          sortable: false,
          valueFormatter({ value }) {
            return capitalCase(value);
          },
        },
        {
          field: 'membersCount',
          headerName: 'Members',
          sortable: true,
        },
        {
          field: 'createdAt',
          headerName: 'Created At',
          sortingOrder: ['desc', 'asc'],
          valueFormatter({ value }) {
            return formatUTCDate(value);
          },
        },
        {
          field: 'lastUserInteractionAt',
          headerName: 'Last User Interaction At',
          sortable: false,
          valueFormatter({ value }) {
            return value ? formatUTCDate(value) : 'Never';
          },
        },
        {
          field: 'actions',
          headerName: 'Actions',
          align: 'center',
          sortable: false,
          flex: 0,
          renderCell({ row }) {
            return (
              <MoreMenu>
                <CopyToClipboard text={row.uuid}>
                  <MenuItem aria-label="copy feed uuid">
                    <ContentCopy />
                    Copy UUID
                  </MenuItem>
                </CopyToClipboard>
                <MenuItem
                  onClick={() => {
                    handleOpenUpdateModal(row);
                  }}
                >
                  <Edit />
                  Update
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleOpenAddAssetModal(row.uuid);
                  }}
                >
                  <Add />
                  Add External Asset To Club
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleOpenAssetsListModal(row.chats, row.topics);
                  }}
                >
                  <RemoveRedEye />
                  View External Assets
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    handleOpenDeleteModal(row.uuid);
                  }}
                >
                  <Delete />
                  Delete
                </MenuItem>
              </MoreMenu>
            );
          },
        },
      ]}
    />
  );
}
