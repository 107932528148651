import { Edit, RateReviewRounded } from '@mui/icons-material';
import { useState } from 'react';
import Button from 'src/components/shared/Buttons/CustomButton';
import Flex from 'src/components/shared/Flex';
import { Collection } from 'src/lib/types/collection';
import ReviewCollectionModal from '../../../ReviewCollectionModal';
import UpdateCollection from '../Update/Update';

interface CollectionActionsProps {
  collection: Collection;
}

export default function CollectionActions({
  collection,
}: CollectionActionsProps): JSX.Element {
  const [showReviewCollectionModal, setShowReviewCollectionModal] =
    useState(false);
  const [showUpdateCollection, setShowUpdateCollection] = useState(false);

  const handleReviewCollection = () => {
    setShowReviewCollectionModal(true);
  };

  const handleOpenUpdateCollection = () => {
    setShowUpdateCollection(true);
  };

  return (
    <>
      <Flex gap={1}>
        <Button
          startIcon={<RateReviewRounded />}
          onClick={handleReviewCollection}
          color="secondary"
          variant="outlined"
        >
          Review
        </Button>
        <Button
          startIcon={<Edit />}
          onClick={handleOpenUpdateCollection}
          color="secondary"
          variant="outlined"
        >
          Edit Collection
        </Button>
      </Flex>
      <ReviewCollectionModal
        collection={collection}
        open={showReviewCollectionModal}
        onClose={() => setShowReviewCollectionModal(false)}
      />
      <UpdateCollection
        open={showUpdateCollection}
        onClose={() => setShowUpdateCollection(false)}
        collection={collection}
      />
    </>
  );
}
