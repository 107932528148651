import { Card, CardContent, Tab, Tabs } from '@mui/material';
import Clubs from 'src/components/modules/Clubs/view/List';
import Flex from 'src/components/shared/Flex';
import { UserClubRelations } from './types';
import useUserClubs from './useView';

const STATUS_TABS = [
  { value: UserClubRelations.MEMBER, label: 'Member' },
  { value: UserClubRelations.ADMIN, label: 'Admin' },
  { value: UserClubRelations.CREATOR, label: 'Creator' },
];

export default function UserClubs(): JSX.Element {
  const { userUuid, currentRelation, onChangeRelation } = useUserClubs();

  return (
    <Card>
      <Flex sx={{ px: 2, bgcolor: 'background.neutral' }}>
        <Tabs
          allowScrollButtonsMobile
          variant="scrollable"
          scrollButtons="auto"
          value={currentRelation}
          onChange={onChangeRelation}
        >
          {STATUS_TABS.map((tab) => (
            <Tab
              disableRipple
              key={tab.value}
              label={tab.label}
              value={tab.value}
            />
          ))}
        </Tabs>
      </Flex>
      <CardContent>
        <Clubs
          showHeader={false}
          relation={currentRelation as UserClubRelations}
          userUuid={userUuid}
        />
      </CardContent>
    </Card>
  );
}
