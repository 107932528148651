import { CommonAttributesForUserAdminLists } from 'src/lib/types/users';
import { parseSearchParams } from 'src/lib/utils/parseSearchParams';
import useSWR, { SWRResponse } from 'swr';

export type InteractedUser = CommonAttributesForUserAdminLists & {
  interactions: number;
};

function parseData(data: InteractedUser[] | undefined): InteractedUser[] {
  if (!data) return [];
  return data;
}
type PossibleParams = {
  page: number;
  size: number;
};

export function fetchMostInteractedUsers(
  userId: string,
  params: PossibleParams
): SWRResponse<InteractedUser[]> {
  const parsedParams = parseSearchParams(params ?? {});

  const { data, ...response } = useSWR(
    `admin/users/${userId}/activity/most-interacted-users?${parsedParams}`
  );

  return {
    ...response,
    data: parseData(data),
  };
}
