import { OpenInNew } from '@mui/icons-material';
import { IconButton, Stack, Typography } from '@mui/material';
import { GridColumns } from '@mui/x-data-grid';
import { RevenueEvent } from 'src/components/modules/RevenueEvents/types/RevenueEvent';
import Link from 'src/components/shared/Link';
import MagicTable from 'src/components/shared/MagicTable/MagicTable';
import { WithProduct } from 'src/lib/types/product';
import Routes from 'src/routes/Routes';
import Label from 'src/template/components/Label';

type Props = {
  data: Array<WithProduct<RevenueEvent & { isActive: boolean }>>;
  isLoading: boolean;
};
export default function UserSubscriptionsView({
  data,
  isLoading,
}: Props): JSX.Element {
  return (
    <MagicTable.Container>
      <MagicTable
        loading={isLoading}
        rows={data}
        hideFooter
        columns={columns}
      />
    </MagicTable.Container>
  );
}

const columns: GridColumns<WithProduct<RevenueEvent & { isActive: boolean }>> =
  [
    {
      field: 'product',
      headerName: 'Product',
      sortable: false,
      flex: 2,
      renderCell({ row }) {
        if (row.product.name)
          return (
            <Stack>
              <Typography>{row.product.name}</Typography>
              <Typography fontSize="12px" color="GrayText">
                {row.product.productId}
              </Typography>
            </Stack>
          );
        return row.product.productId;
      },
    },
    {
      field: 'isActive',
      headerName: 'Status',
      renderCell({ row }) {
        if (!row.subscriptionExpiresDate) return 'N/A';
        return (
          <Label color={row.isActive ? 'success' : 'error'}>
            {row.isActive ? 'Active' : 'Inactive'}
          </Label>
        );
      },
    },
    {
      field: 'isFreeTrial',
      headerName: 'Free Trial',
    },
    {
      field: 'action',
      headerName: '',
      align: 'center',
      flex: 1 / 2,
      renderCell({ row }) {
        return (
          <IconButton
            component={Link}
            to={`${Routes.subscription(
              row.originalTransactionId,
              row.userUuid
            )}?user=${row.userUuid}`}
          >
            <OpenInNew />
          </IconButton>
        );
      },
    },
  ];
