import { useParams } from 'react-router';
import { CommonDateStrings } from 'src/lib/constants/CommonDates';
import { DEFAULT_INITIAL_PAGE_SIZE } from 'src/lib/constants/pagination';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';
import fetchUserPurchases from '../../../services/fetchUserPurchases';
import fetchUserSellers from '../../../services/fetchUserSellers';
import { UserPurchaseSubTab } from './types';

const START_DATE_DEFAULT_VALUE =
  CommonDateStrings.firstStickyOrderBookMatch.date;
const END_DATE_DEFAULT_VALUE = CommonDateStrings.today.date;
export default function useUserPurchasesView() {
  const { uuid } = useParams<{ uuid: string }>();
  const { allSearchParams, removeParam, addParam } = useURLSearchParams({
    page: '0',
    size: DEFAULT_INITIAL_PAGE_SIZE.toString(),
    startDate: START_DATE_DEFAULT_VALUE,
    endDate: END_DATE_DEFAULT_VALUE,
    purchasesSection: UserPurchaseSubTab.PURCHASED_FROM,

    sortBy: 'totalPrice',
    order: 'desc',
  });

  const { data: purchasesData, isLoading: purchasesIsLoading } =
    fetchUserPurchases(uuid!, getPossibleParams(allSearchParams));

  const { data: sellersData, isLoading: sellersIsLoading } = fetchUserSellers(
    uuid!,
    {
      page: Number(allSearchParams.page!),
      size: Number(allSearchParams.size!),
      order: allSearchParams.order,
      sortBy: allSearchParams.sortBy,
    }
  );

  function onChangeFilter(field: string, value: string | null | undefined) {
    if (!value) removeParam(field);
    else addParam(field, value);
  }

  return {
    purchasesData,
    purchasesIsLoading,
    sellersData,
    sellersIsLoading,
    page: Number(allSearchParams.page!),
    size: Number(allSearchParams.size!),
    order: allSearchParams.order,
    sortBy: allSearchParams.sortBy,
    section: allSearchParams.purchasesSection! as UserPurchaseSubTab,
    onChangeFilter,
    filter: {
      startDate: allSearchParams.startDate,
      endDate: allSearchParams.endDate,
    },
  };
}

function getPossibleParams(
  allSearchParams: Record<string, string | undefined>
): Parameters<typeof fetchUserPurchases>[1] {
  const { page, size, startDate, endDate } = allSearchParams;
  return {
    filter: {
      startDate: startDate ? `${startDate} 00:00:00` : START_DATE_DEFAULT_VALUE,
      endDate: endDate ? `${endDate} 23:59:59` : END_DATE_DEFAULT_VALUE,
    },
    size: Number(size),
    page: Number(page),
  };
}
