import { Card, CardHeader } from '@mui/material';
import NFTPackDeliveriesTable from 'src/components/modules/NFTPacks/components/Deliveries/Table';

type Props = {
  data: any;
  isLoading: boolean;
  page: number;
  size: number;
};

export default function UserNftPackDeliveriesView({
  data,
  isLoading,
  page,
  size,
}: Props): JSX.Element {
  return (
    <Card>
      <CardHeader title="NFT Pack Deliveries" />
      <NFTPackDeliveriesTable
        isLoading={isLoading}
        data={data?.body ?? []}
        page={page}
        size={size}
        totalCount={data?.meta?.totalCount ?? 0}
        order="desc"
        sortBy="lastSession"
        sortable={false}
      />
    </Card>
  );
}
