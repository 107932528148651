import { GridSortDirection } from '@mui/x-data-grid';
import UserSalesView from './View';
import useUserSalesView from './useView';

export default function UserSales(): JSX.Element {
  const {
    salesData,
    salesIsLoading,
    buyersData,
    buyersIsLoading,
    page,
    size,
    order,
    sortBy,
    section,
    filter,
    onChangeFilter,
  } = useUserSalesView();
  return (
    <UserSalesView
      salesData={salesData ?? []}
      saleIsLoading={salesIsLoading}
      buyersData={buyersData ?? []}
      buyersIsLoading={buyersIsLoading}
      page={page}
      size={size}
      order={order as GridSortDirection}
      sortBy={sortBy!}
      section={section}
      filter={filter}
      onChangeFilter={onChangeFilter}
    />
  );
}
