import { useParams } from 'react-router';
import { fetchGeneralPlayerStatsByUuid } from 'src/components/modules/Games/services/fetchGeneralPlayerStatsByUuid';
import { CommonDateStrings } from 'src/lib/constants/CommonDates';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';

export default function useUserGameSessions() {
  const { uuid } = useParams<{ uuid: string }>();
  const { allSearchParams } = useURLSearchParams(initialParams);

  const params = {
    startDate: allSearchParams.startDate ?? initialParams.startDate,
    endDate: allSearchParams.endDate ?? initialParams.endDate,
  };

  const { data, isLoading } = fetchGeneralPlayerStatsByUuid(uuid!, {
    endDate: `${params.endDate}T23:59:59.999Z`,
    startDate: `${params.startDate}T00:00:00.000Z`,
  });

  return {
    data,
    isLoading,
  };
}

const initialParams = {
  startDate: CommonDateStrings.sevenDaysAgo.dateTime.split('T')[0],
  endDate: CommonDateStrings.today.dateTime.split('T')[0],
};
