import { Card, CardHeader, TextField } from '@mui/material';
import { GridSortDirection } from '@mui/x-data-grid';
import { capitalCase } from 'change-case';
import { format } from 'date-fns';
import Flex from 'src/components/shared/Flex';
import Link from 'src/components/shared/Link';
import MagicTable from 'src/components/shared/MagicTable/MagicTable';
import MagicTableNFTCell from 'src/components/shared/MagicTable/NFTCell';
import MagicTableUserCell from 'src/components/shared/MagicTable/UserCell';
import { CommonDateStrings } from 'src/lib/constants/CommonDates';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';
import { formatFractionNumber } from 'src/lib/utils/formatFractionNumber';
import { getTruncatedText } from 'src/lib/utils/formatters';
import Routes from 'src/routes/Routes';
import Label from 'src/template/components/Label';
import { UserPurchase } from '../../../types/UserPurchase';
import { UserSeller } from '../../../types/UserSeller';
import { FilterFormValues, UserPurchaseSubTab } from './types';

type Props = {
  purchasesData: UserPurchase[];
  purchasesIsLoading: boolean;
  sellersData: UserSeller[];
  sellersIsLoading: boolean;
  page: number;
  size: number;
  order: GridSortDirection;
  sortBy: string;
  section: UserPurchaseSubTab;
  filter: Partial<FilterFormValues>;
  onChangeFilter: (field: keyof FilterFormValues, value: string) => void;
};
export default function UserPurchasesView({
  purchasesData,
  purchasesIsLoading,
  sellersData,
  sellersIsLoading,
  page,
  size,
  order,
  sortBy,
  section,
  filter,
  onChangeFilter,
}: Props): JSX.Element {
  const sections = {
    [UserPurchaseSubTab.PURCHASES]: (
      <PurchasesTable
        data={purchasesData}
        isLoading={purchasesIsLoading}
        page={page}
        size={size}
        key={UserPurchaseSubTab.PURCHASES}
      />
    ),
    [UserPurchaseSubTab.PURCHASED_FROM]: (
      <SellersTable
        data={sellersData}
        isLoading={sellersIsLoading}
        page={page}
        size={size}
        order={order}
        sortBy={sortBy}
        key={UserPurchaseSubTab.PURCHASED_FROM}
      />
    ),
  };
  return (
    <MagicTable.Container>
      <MagicTable.Tabs
        tabs={Object.values(UserPurchaseSubTab).map((purchaseSubTab) => ({
          label: capitalCase(purchaseSubTab),
          value: purchaseSubTab,
        }))}
        defaultTab={UserPurchaseSubTab.PURCHASED_FROM}
        tabsConfig={{
          queryName: 'purchasesSection',
        }}
      />

      <Card>
        <CardHeader
          action={
            section === UserPurchaseSubTab.PURCHASES && (
              <Flex gap={1} pb={2}>
                <TextField
                  label="Starting Date"
                  fullWidth
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  defaultValue={filter.startDate}
                  inputProps={{
                    min: CommonDateStrings.firstStickyOrderBookMatch.date,
                    max: CommonDateStrings.today.date,
                  }}
                  onChange={({ target: { value } }) => {
                    onChangeFilter('startDate', value);
                  }}
                />
                <TextField
                  label="Ending Date"
                  fullWidth
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  defaultValue={filter.endDate}
                  inputProps={{
                    min: CommonDateStrings.firstStickyOrderBookMatch.date,
                    max: CommonDateStrings.today.date,
                  }}
                  onChange={({ target: { value } }) => {
                    onChangeFilter('endDate', value);
                  }}
                />
              </Flex>
            )
          }
        />
        {sections[section]}
      </Card>
    </MagicTable.Container>
  );
}

type PurchasesTableProps = {
  data: UserPurchase[];
  isLoading: boolean;
  page: number;
  size: number;
};

export function PurchasesTable({
  data,
  isLoading,
  page,
  size,
}: PurchasesTableProps) {
  const { addParam } = useURLSearchParams();
  return (
    <MagicTable
      loading={isLoading}
      pageSize={size}
      page={page}
      hideNumberOfRows
      paginationMode="server"
      onPageChange={(newPage) => {
        addParam('page', newPage);
      }}
      onPageSizeChange={(newSize) => {
        addParam('size', newSize);
      }}
      columns={[
        {
          field: 'userTo',
          headerName: 'Bought From',
          sortable: false,
          renderCell: ({ value }) => (
            <>
              <MagicTableUserCell user={value} />
              <Label color={value.active ? 'success' : 'error'} sx={{ ml: 1 }}>
                {value.active ? 'Active' : 'Inactive'}
              </Label>
            </>
          ),
        },
        {
          field: 'nft',
          headerName: 'NFT',
          sortable: false,
          renderCell: ({ value }) => <MagicTableNFTCell nft={value} />,
        },
        {
          field: 'poolUuid',
          headerName: 'Pool',
          sortable: false,
          flex: 1 / 2,
          align: 'left',
          renderCell: ({ value }) => (
            <Link to={Routes.transactions.pool(value)} target="_blank">
              {getTruncatedText(value, 6, 3)}
            </Link>
          ),
        },
        {
          field: 'editions',
          headerName: 'Editions',
          sortable: false,
          align: 'right',
          flex: 0,
        },
        {
          field: 'unitPrice',
          headerName: 'Price',
          sortable: false,
          align: 'right',
          flex: 0,
          valueFormatter({ value }) {
            return formatFractionNumber(value);
          },
        },
        {
          field: 'createdAt',
          headerName: 'Purchase Date',
          sortable: false,
          align: 'right',
          flex: 1 / 2,
          valueFormatter: ({ value }) => format(value, 'dd/MM/yyyy · HH:mm'),
        },
      ]}
      rows={data}
      getRowId={(r) => `${r.poolUuid}${r.matchUuid}`}
    />
  );
}

type SellersTableProps = {
  data: UserSeller[];
  isLoading: boolean;
  page: number;
  size: number;
  sortBy: string;
  order: GridSortDirection;
};

export function SellersTable({
  data,
  isLoading,
  page,
  size,
  sortBy,
  order,
}: SellersTableProps) {
  const { addParam, removeParam } = useURLSearchParams();

  return (
    <MagicTable
      loading={isLoading}
      pageSize={size}
      page={page}
      hideNumberOfRows
      paginationMode="server"
      onPageChange={(newPage) => {
        addParam('page', newPage);
      }}
      onPageSizeChange={(newSize) => {
        addParam('size', newSize);
      }}
      sortModel={[{ field: sortBy, sort: order }]}
      onSortModelChange={(model) => {
        const currentModel = model[0];
        if (currentModel) {
          addParam('sortBy', currentModel.field);
          if (currentModel.sort) addParam('order', currentModel.sort);
        } else {
          removeParam('order');
          removeParam('sortBy');
        }
      }}
      columns={[
        {
          field: 'userTo',
          headerName: 'Seller',
          sortable: false,
          renderCell: ({ value }) => (
            <>
              <MagicTableUserCell user={value} />
              <Label color={value.active ? 'success' : 'error'} sx={{ ml: 1 }}>
                {value.active ? 'Active' : 'Inactive'}
              </Label>
            </>
          ),
        },
        {
          field: 'editions',
          headerName: 'Editions',
          sortable: true,
          align: 'right',
          flex: 0,
        },
        {
          field: 'totalPrice',
          headerName: 'Volume',
          sortable: true,
          align: 'right',
          flex: 0,
          sortingOrder: ['desc', 'asc'],
          valueFormatter({ value }) {
            return formatFractionNumber(value);
          },
        },
        {
          field: 'latestMatchCreatedAt',
          headerName: 'Last Transaction Date',
          sortable: false,
          align: 'right',
          flex: 1 / 2,
          valueFormatter: ({ value }) => format(value, 'dd/MM/yyyy · HH:mm'),
        },
      ]}
      rows={data}
      getRowId={(r) => `${r.userTo.uuid}`}
    />
  );
}
