import { useParams } from 'react-router';
import { fetchGeneralPlayerStatsByUuid } from 'src/components/modules/Games/services/fetchGeneralPlayerStatsByUuid';

export default function useUserGameRatings() {
  const { uuid } = useParams<{ uuid: string }>();

  const { data, isLoading } = fetchGeneralPlayerStatsByUuid(uuid!, {});

  return {
    data,
    isLoading,
  };
}
