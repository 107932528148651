/* eslint-disable no-param-reassign */
import { useForm } from 'react-hook-form';
import useModalStore from 'src/lib/stores/useModalStore';

import { yupResolver } from '@hookform/resolvers/yup';
import useNotify from 'src/lib/hooks/useNotify';
import { OBJECT_MODERATION_HUMAN_REVIEW } from 'src/lib/types/objectModeration';
import * as yup from 'yup';
import { ReviewEmitter } from '../../events/ReviewEmitter';
import { sendHumanReview } from '../../services/sendHumanReview';
import { ModerationFormOfVideos, UseReviewFormProps } from './types';

const validationSchema = yup.object().shape({
  // human review needs to be a string and be in enum OBJECT_MODERATION_HUMAN_REVIEW
  humanReview: yup
    .string()
    .required('Human review is required')
    .oneOf(Object.values(OBJECT_MODERATION_HUMAN_REVIEW)),
  humanReviewRejectionReason: yup.string().when('humanReview', {
    is: OBJECT_MODERATION_HUMAN_REVIEW.REJECTION,
    then: yup.string().required('Rejection reason is required').min(1),
  }),
});

export const useReviewForm = (props: UseReviewFormProps) => {
  const { defaultValues, objectUuid, objectType } = props;
  const { control, handleSubmit, watch, setValue, getFieldState, formState } =
    useForm<ModerationFormOfVideos>({
      defaultValues,
      resolver: yupResolver(validationSchema),
    });
  const currentHumanReview = watch('humanReview');
  const { closeModal, isOpen } = useModalStore((state) => state);
  const notify = useNotify();

  const onSubmit = async (data: ModerationFormOfVideos) => {
    try {
      // if is not rejection, we don't need to send the rejection reason and block user
      if (data.humanReview !== OBJECT_MODERATION_HUMAN_REVIEW.REJECTION) {
        data.humanReviewRejectionReason = null;
        data.internalJustification = null;
        data.blockUser = false;
      }

      const { error } = await sendHumanReview(objectUuid, objectType, data);

      if (error) {
        notify(error, { variant: 'error' });
      } else {
        notify('Review created!', { variant: 'success' });
        ReviewEmitter.emit('ReviewCreated', null);
        closeModal();
      }
    } catch (error: any) {
      notify(error?.message || error?.description, { variant: 'error' });
    }
  };

  return {
    control,
    handleSubmit,
    onSubmit,
    formState,
    isOpen,
    closeModal,
    currentHumanReview,
    setValue,
    getFieldState,
  };
};
