import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import {
  Alert,
  Autocomplete,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  MenuItem,
  Stack,
  TextField,
} from '@mui/material';
import { Controller } from 'react-hook-form';
import Modal from 'src/components/shared/Modal';
import {
  ModerationCategory,
  OBJECT_MODERATION_HUMAN_REVIEW,
} from 'src/lib/types/objectModeration';

import { capitalCase } from 'change-case';
import Button from 'src/components/shared/Buttons/CustomButton';
import Flex from 'src/components/shared/Flex';
import { UseReviewFormProps } from './types';
import { useReviewForm } from './useReviewForm';

const reviewStatusLabel: Record<OBJECT_MODERATION_HUMAN_REVIEW, string> = {
  [OBJECT_MODERATION_HUMAN_REVIEW.FULL_APPROVAL]: 'Full Approval',
  [OBJECT_MODERATION_HUMAN_REVIEW.LIMITED_APPROVAL]: 'Limited Approval',
  [OBJECT_MODERATION_HUMAN_REVIEW.REJECTION]: 'Rejection',
  [OBJECT_MODERATION_HUMAN_REVIEW.NOT_SET]: 'Not Set',
};

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function ReviewForm(props: UseReviewFormProps) {
  const {
    isOpen,
    closeModal,
    handleSubmit,
    control,
    onSubmit,
    formState: { isSubmitting, errors },
    currentHumanReview,
    setValue,
    getFieldState,
  } = useReviewForm(props);

  const isRejection =
    currentHumanReview === OBJECT_MODERATION_HUMAN_REVIEW.REJECTION;

  return (
    <Modal open={isOpen} onClose={closeModal}>
      <Card
        sx={{
          width: '600px',
        }}
      >
        <CardHeader title="Review Object" />
        <CardContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Stack spacing={2}>
              <Controller
                name="humanReview"
                control={control}
                render={({ field }) => (
                  <TextField
                    select
                    label="Review Item as:"
                    fullWidth
                    {...field}
                    variant="outlined"
                  >
                    {Object.values(OBJECT_MODERATION_HUMAN_REVIEW).map(
                      (type) => (
                        <MenuItem key={type} value={type}>
                          {reviewStatusLabel[type]}
                        </MenuItem>
                      )
                    )}
                  </TextField>
                )}
              />

              {getFieldState(`humanReview`).error?.message && (
                <Alert severity="error">
                  {getFieldState(`humanReview`).error?.message}
                </Alert>
              )}

              {isRejection && (
                <>
                  <Controller
                    name="humanReviewRejectionReason"
                    control={control}
                    render={({ field }) => (
                      <Autocomplete
                        multiple
                        id="checkboxes-rejection-reason"
                        options={Object.values(ModerationCategory)}
                        disableCloseOnSelect
                        renderOption={(_props, option, { selected }) => (
                          <li {..._props}>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={selected}
                            />
                            {capitalCase(option)}
                          </li>
                        )}
                        style={{ width: '100%' }}
                        onChange={(e, value) => {
                          setValue(
                            'humanReviewRejectionReason',
                            value.sort().join(',')
                          );
                        }}
                        defaultValue={[]}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Rejection Reasons"
                            placeholder="Select Reasons"
                            {...field}
                          />
                        )}
                      />
                    )}
                  />
                  {getFieldState(`humanReviewRejectionReason`).error
                    ?.message && (
                    <Alert severity="error">
                      {
                        getFieldState(`humanReviewRejectionReason`).error
                          ?.message
                      }
                    </Alert>
                  )}
                </>
              )}

              {isRejection && (
                <Controller
                  name="internalJustification"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      label="Internal Justification"
                      fullWidth
                      {...field}
                      variant="outlined"
                      maxRows={10}
                      minRows={10}
                    />
                  )}
                />
              )}

              <Controller
                name="reviewNote"
                control={control}
                render={({ field }) => (
                  <TextField
                    label="Review Note (Optional)"
                    fullWidth
                    {...field}
                    variant="outlined"
                    multiline
                    maxRows={10}
                    minRows={10}
                  />
                )}
              />

              {isRejection && (
                <Flex
                  sx={{
                    width: '50%',
                  }}
                >
                  Block User
                  <Controller
                    name="blockUser"
                    control={control}
                    render={({ field }) => (
                      <Checkbox
                        {...field}
                        checked={field.value}
                        onChange={(e) => {
                          field.onChange(e.target.checked);
                        }}
                      />
                    )}
                  />
                </Flex>
              )}

              <Button
                variant="contained"
                color="secondary"
                type="submit"
                loading={isSubmitting}
                size="large"
              >
                Save
              </Button>
            </Stack>
          </form>
        </CardContent>
      </Card>
    </Modal>
  );
}

export default ReviewForm;
