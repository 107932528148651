import formatStickyCoinValue from 'src/lib/utils/formatStickyCoinValue';
import { parseSearchParams } from 'src/lib/utils/parseSearchParams';
import useSWR, { SWRConfiguration, SWRResponse } from 'swr';
import { UserPurchase } from '../types/UserPurchase';

function parseData(data: UserPurchase[]): UserPurchase[] {
  return data.map((d) => ({
    ...d,
    unitPrice: formatStickyCoinValue(d.unitPrice),
  }));
}
export default function fetchUserPurchases(
  userId: string,
  params?: Partial<PossibleParams>,
  options?: SWRConfiguration
): SWRResponse<UserPurchase[]> {
  const parsedParams = parseSearchParams(params ?? {});
  const { data, ...response } = useSWR(
    `admin/users/${userId}/purchases?${parsedParams}`,
    options
  );

  return { ...response, data: parseData(data ?? []) };
}

type PossibleParams = {
  page: number | string;
  size: number | string;
  filter: {
    startDate: string;
    endDate: string;
  };
};
