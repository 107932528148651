import { Box, Stack } from '@mui/material';
import Flex from 'src/components/shared/Flex';
import NumberInfo from 'src/components/shared/NumberInfo/NumberInfo';
import { fetchUser } from 'src/lib/services/users';
import ProfileFollowers from './Followers';
import ProfileFollowing from './Following';

type Props = {
  userId: string;
};
export default function ProfileSocialActivity({ userId }: Props): JSX.Element {
  const { data } = fetchUser(userId);

  return (
    <Stack spacing={4} px="15%">
      <Flex justifyContent="center">
        <NumberInfo title="Profile Views Count" total={data?.views ?? 0} />
      </Flex>
      <Box
        display="grid"
        gridTemplateColumns="repeat(auto-fit, minmax(250px, 1fr))"
        gap={8}
      >
        <ProfileFollowers userId={userId} />
        <ProfileFollowing userId={userId} />
      </Box>
    </Stack>
  );
}
