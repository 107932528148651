import UserNftPackDeliveriesView from './View';
import useUserNftPackDeliveriesView from './useView';

export default function UserNftPackDeliveries(): JSX.Element {
  const { data, isLoading, page, size } = useUserNftPackDeliveriesView();

  return (
    <UserNftPackDeliveriesView
      data={data ?? []}
      isLoading={isLoading}
      page={page}
      size={size}
    />
  );
}
