import { TabContext, TabPanel } from '@mui/lab';
import { Stack, Tab, Tabs } from '@mui/material';
import SectionDivider from 'src/components/shared/SectionDivider';
import useTabs from 'src/template/hooks/useTabs';
import NftActivitySection from './NftActivity/NftActivitySection';
import UserNftPackDeliveries from './NftPackDeliveries';
import UserPurchases from './Purchases';
import UserSales from './Sales';
import ProfileSocialActivity from './Social';
import UserTransfers from './Transfers';
import UserBehaviorSection from './UserBehavior/UserBehaviorSection';

const ActivityTabs = [
  {
    tab: 'behavior',
    label: 'Behavior',
    component: (userUuid: string) => (
      <Stack gap={3}>
        <NftActivitySection userUuid={userUuid} />
        <SectionDivider section="User Behavior" />
        <UserBehaviorSection userUuid={userUuid} />
      </Stack>
    ),
  },
  {
    tab: 'sales',
    label: 'Sales',
    component: () => <UserSales />,
  },
  {
    tab: 'purchases',
    label: 'Purchases',
    component: () => <UserPurchases />,
  },
  {
    tab: 'transfers',
    label: 'Transfers',
    component: () => <UserTransfers />,
  },
  {
    tab: 'NFT Pack Deliveries',
    label: 'NFT Pack Deliveries',
    component: () => <UserNftPackDeliveries />,
  },
  {
    tab: 'social',
    label: 'Social',
    component: (userId: string) => <ProfileSocialActivity userId={userId} />,
  },
] as const;

type ActivitySessionProps = {
  userUuid: string;
};

export default function ActivityPage({ userUuid }: ActivitySessionProps) {
  const { currentTab, onChangeTab } = useTabs(ActivityTabs[0].tab, {
    queryName: 'subtab',
  });

  return (
    <TabContext value={currentTab ?? ActivityTabs[0].tab}>
      <Tabs
        value={currentTab}
        onChange={onChangeTab}
        centered
        sx={{ px: 2, mb: 4, borderRadius: 1 }}
      >
        {ActivityTabs.map((activity) => (
          <Tab
            disableRipple
            key={activity.tab}
            label={activity.label}
            value={activity.tab}
          />
        ))}
      </Tabs>
      {ActivityTabs.map((activity) => (
        <TabPanel key={activity.tab} value={activity.tab}>
          {activity.component(userUuid)}
        </TabPanel>
      ))}
    </TabContext>
  );
}
