import { GameOrientations, GamePublishRights } from 'src/lib/types/game';
import { v4 } from 'uuid';
import GameForm from '../../components/Forms/GameForm';
import useCreateGameView from './useView';

export default function CreateGameView(): JSX.Element {
  const { handleCreateGame } = useCreateGameView();
  return (
    <GameForm
      defaultValues={{
        game: {
          uuid: v4(),
          name: '',
          description: '',
          gamePublisher: '',
          gameCategoryUuid: '',
          // gameTagsUuids: [],
          developerName: '',

          gameTagsNames: [],
          isMobile: false,
          orientation: GameOrientations.AUTOMATIC,
          publishRightsType: GamePublishRights.HAVE_RIGHTS,
          secondaryLink: '',
          hasAgreedTerms: true,
        },
        version: {
          version: '',
          url: '',
          imageUrl: '',
          ageRestriction: undefined,
          gameModesUuids: [],

          additionalComments: '',
          hasAds: true,
        },
        objectLinks: [],
        objectLinkSearch: '',
      }}
      onSubmit={handleCreateGame}
      title="Create Game"
    />
  );
}
