import CardInfo from 'src/components/shared/CardInfo/CardInfo';
import Flex from 'src/components/shared/Flex';
import NumberInfo from 'src/components/shared/NumberInfo/NumberInfo';
import { UserChannelStats } from 'src/lib/types/channelWithAdminAnalytics';
import { formatMilliseconds } from 'src/lib/utils/formatMilliseconds';

export default function UserChannelInfos({
  views,
  watchedTime,
  clicks,
}: UserChannelStats) {
  return (
    <Flex gap={2} flexWrap={'wrap'} justifyContent={'center'}>
      <NumberInfo title="Views" total={views} />
      <CardInfo title="Watched time" text={formatMilliseconds(watchedTime)} />
      <NumberInfo title="Clicks" total={clicks} />
    </Flex>
  );
}
