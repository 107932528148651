import { styled, useTheme } from '@mui/material';
import { merge } from 'lodash';
import { useMemo } from 'react';
import ReactApexChart from 'react-apexcharts';
import { BaseOptionChart } from 'src/template/components/chart';
import { fNumber } from 'src/template/utils/formatNumber';
import { PieChartData } from './PieChartTypes';

const CHART_HEIGHT = 392;
const LEGEND_HEIGHT = 72;

const ChartWrapperStyle = styled('div')(({ theme }) => ({
  height: CHART_HEIGHT,
  marginTop: theme.spacing(5),
  '& .apexcharts-canvas svg': { height: CHART_HEIGHT },
  '& .apexcharts-canvas svg,.apexcharts-canvas foreignObject': {
    overflow: 'visible',
  },
  '& .apexcharts-legend': {
    height: LEGEND_HEIGHT,
    alignContent: 'center',
    position: 'relative !important' as 'relative',
    borderTop: `solid 1px ${theme.palette.divider}`,
    top: `calc(${CHART_HEIGHT - LEGEND_HEIGHT}px) !important`,
  },
}));

export type PieChartProps = {
  data: PieChartData[];
  legendClickCallback?: (e: any, chart?: any, options?: any) => void;
  dataPointSelectionCallback?: (e: any, chart?: any, options?: any) => void;
};

export default function PieChart({
  data,
  legendClickCallback,
  dataPointSelectionCallback,
}: PieChartProps) {
  const theme = useTheme();

  const series = useMemo(() => data.map((item) => item.value), [data]);

  const chartOptions = merge(BaseOptionChart(), {
    chart: {
      events: {
        legendClick: legendClickCallback,
        dataPointSelection: dataPointSelectionCallback,
      },
    },
    colors: data.map((item) => item.color),
    labels: data.map((item) => item.label),
    stroke: { colors: [theme.palette.background.paper] },
    legend: { floating: true, horizontalAlign: 'center' },
    tooltip: {
      fillSeriesColor: false,
      y: {
        formatter: (seriesName: string) => fNumber(seriesName),
        title: {
          formatter: (seriesName: string) => `${seriesName}`,
        },
      },
    },
    plotOptions: {
      pie: {
        donut: {
          size: '90%',
          labels: {
            value: {
              formatter: (val: number | string) => fNumber(val),
            },
            total: {
              formatter: (w: { globals: { seriesTotals: number[] } }) => {
                const sum = w.globals.seriesTotals.reduce((a, b) => a + b, 0);
                return fNumber(sum);
              },
            },
          },
        },
      },
    },
  });

  return (
    <ChartWrapperStyle dir="ltr">
      <ReactApexChart
        type="donut"
        series={series}
        options={chartOptions}
        height={280}
      />
    </ChartWrapperStyle>
  );
}
