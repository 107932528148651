import { useParams } from 'react-router';
import { CommonDateStrings } from 'src/lib/constants/CommonDates';
import { DEFAULT_INITIAL_PAGE_SIZE } from 'src/lib/constants/pagination';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';
import fetchUserBuyers from '../../../services/fetchUserBuyers';
import fetchUserSales from '../../../services/fetchUserSales';
import { UserSaleSubTab } from './types';

const START_DATE_DEFAULT_VALUE =
  CommonDateStrings.firstStickyOrderBookMatch.date;
const END_DATE_DEFAULT_VALUE = CommonDateStrings.today.date;

export default function useUserSalesView() {
  const { uuid } = useParams<{ uuid: string }>();
  const { allSearchParams, removeParam, addParam } = useURLSearchParams({
    page: '0',
    size: DEFAULT_INITIAL_PAGE_SIZE.toString(),
    startDate: START_DATE_DEFAULT_VALUE,
    endDate: END_DATE_DEFAULT_VALUE,
    salesSection: UserSaleSubTab.SOLD_TO,

    sortBy: 'totalPrice',
    order: 'desc',
  });

  const { data: salesData, isLoading: salesIsLoading } = fetchUserSales(
    uuid!,
    getPossibleParams(allSearchParams)
  );

  const { data: buyersData, isLoading: buyersIsLoading } = fetchUserBuyers(
    uuid!,
    {
      page: Number(allSearchParams.page!),
      size: Number(allSearchParams.size!),
      order: allSearchParams.order,
      sortBy: allSearchParams.sortBy,
    }
  );

  function onChangeFilter(field: string, value: string | null | undefined) {
    if (!value) removeParam(field);
    else addParam(field, value);
  }

  return {
    salesData,
    salesIsLoading,
    buyersData,
    buyersIsLoading,
    page: Number(allSearchParams.page!),
    size: Number(allSearchParams.size!),
    order: allSearchParams.order,
    sortBy: allSearchParams.sortBy,
    section: allSearchParams.salesSection! as UserSaleSubTab,
    onChangeFilter,
    filter: {
      startDate: allSearchParams.startDate,
      endDate: allSearchParams.endDate,
    },
  };
}

function getPossibleParams(
  allSearchParams: Record<string, string | undefined>
): Parameters<typeof fetchUserSales>[1] {
  const { page, size, startDate, endDate } = allSearchParams;
  return {
    filter: {
      startDate: startDate ? `${startDate}T00:00:00.000Z` : '',
      endDate: endDate ? `${endDate}T23:59:59.000Z` : '',
    },
    size: Number(size),
    page: Number(page),
  };
}
