import { useParams } from 'react-router';
import useTabs from 'src/template/hooks/useTabs';
import { fetchUserChannelStats } from '../../services/fetchUserChannelStats';
import { UserChannelRelations } from './types';

export default function useUserChannels() {
  const { uuid: userUuid } = useParams<{ uuid: string }>();
  const { currentTab: currentRelation, onChangeTab: onChangeRelation } =
    useTabs(UserChannelRelations.FOLLOWER, {
      queryName: 'relation',
    });

  const { data: userChannelStats } = fetchUserChannelStats(userUuid!, {});

  return {
    userUuid,
    currentRelation,
    onChangeRelation,
    userChannelStats,
  };
}
