import { useParams } from 'react-router';
import { fetchSubscriptions } from 'src/components/modules/RevenueEvents/services/fetchSubscriptions';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';

export default function useUserSubscriptionsView() {
  const { uuid } = useParams<{ uuid: string }>();
  const { allSearchParams } = useURLSearchParams({
    size: '100000',
  });
  const { data, isLoading } = fetchSubscriptions({
    size: allSearchParams.size,
    filter: {
      userUuid: uuid,
    },
  });

  return {
    data,
    isLoading,
  };
}
