import { Navigate, useParams } from 'react-router';

import { Card, Grid, Stack } from '@mui/material';
import FileDisplay from 'src/components/shared/Media/FileDisplay';
import { BlockchainNetwork } from 'src/lib/constants/blockchain';
import { fetchCollection } from 'src/lib/services/collections';
import { fetchNFT } from 'src/lib/services/nft';
import HeaderBreadcrumbs from 'src/template/components/HeaderBreadcrumbs';
import LoadingScreen from 'src/template/components/LoadingScreen';
import CollectionAddressCard from '../Collections/CollectionAddressCard';
import CollectionCreatorCard from '../Collections/CollectionCreatorCard';
import NFTAbout from './NFTAbout';
import NFTActions from './NFTActions';

export default function NFT() {
  const { uuid } = useParams<'uuid'>();
  if (!uuid) return <Navigate to="/collections" replace />;

  const { data: nft } = fetchNFT(uuid);
  const { data: collection } = fetchCollection(nft?.collectionUuid ?? null);

  return !nft ? (
    <LoadingScreen />
  ) : (
    <>
      <HeaderBreadcrumbs
        heading={nft?.name || 'Empty Name'}
        links={[
          { name: 'Collections', href: '/collections' },
          {
            name: collection?.name ?? 'Unnamed Collection',
            href: `/collections/${nft?.collectionUuid ?? ''}`,
          },
          { name: nft?.name ?? 'Unnamed NFT' },
        ]}
        action={<NFTActions nft={nft} />}
      />
      <Stack spacing={3} width="100%">
        <Grid padding={0} container spacing={3} mb={2}>
          <Grid item xs={12} md={7}>
            <Card>
              <FileDisplay fileUrl={nft?.fileUrl} width="100%" height="auto" />
            </Card>
          </Grid>
          <Grid item xs={12} md={5}>
            <Stack spacing={1}>
              {collection && (
                <>
                  {collection?.user && (
                    <CollectionCreatorCard {...collection.user} />
                  )}
                  {collection.blockchain !== BlockchainNetwork.STICKY &&
                    (collection?.externalWallet || collection?.mintToken) && (
                      <CollectionAddressCard collection={collection} />
                    )}
                </>
              )}
              <NFTAbout nft={nft} />
            </Stack>
          </Grid>
        </Grid>
      </Stack>
    </>
  );
}
