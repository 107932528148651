import { Stack, useTheme } from '@mui/material';
import Loader from 'src/components/shared/Loader';
import { fetchSuspiciousActivity } from '../../../services/fetchSuspiciousActivity';
import { UserNftActivityStats } from '../types';
import { IndividualChart } from './IndividualPieChart/IndividualPieChart';

type SalesChartProp = {
  userUuid: string;
};

type AvailableChartValues = 'amount' | 'quantity';

export default function SalesChart({ userUuid }: SalesChartProp) {
  const { data, isLoading } = fetchSuspiciousActivity(userUuid);
  const hasFinishedLoadingChartsData = !isLoading && data !== undefined;

  return (
    <Stack spacing={3} direction="row" width="100%">
      <NftSalesChart
        data={data}
        hasFinishedLoadingChartsData={hasFinishedLoadingChartsData}
      />
      <NftPurchasesChart
        data={data}
        hasFinishedLoadingChartsData={hasFinishedLoadingChartsData}
      />
      <NftTransfersChart
        data={data}
        hasFinishedLoadingChartsData={hasFinishedLoadingChartsData}
      />
    </Stack>
  );
}

type NftActivityChartProps = {
  data: UserNftActivityStats | undefined;
  hasFinishedLoadingChartsData: boolean;
};

function NftSalesChart({
  data,
  hasFinishedLoadingChartsData,
}: NftActivityChartProps) {
  const theme = useTheme();
  const salesChartData = (show: AvailableChartValues) => [
    {
      label: 'Blocked/Flagged Users',
      value: data?.sales[show]?.suspicious ?? 0,
      color: theme.palette.error.light,
    },
    {
      label: 'Non-Blocked/Flagged Users',
      value: data?.sales[show]?.nonSuspicious ?? 0,
      color: theme.palette.success.light,
    },
  ];

  return hasFinishedLoadingChartsData ? (
    <IndividualChart headerTitle="NFT Sales" chartData={salesChartData} />
  ) : (
    <Loader />
  );
}

function NftPurchasesChart({
  data,
  hasFinishedLoadingChartsData,
}: NftActivityChartProps) {
  const theme = useTheme();
  const purchasesChartData = (show: AvailableChartValues) => [
    {
      label: 'From Blocked/Flagged Users',
      value: data?.purchases[show]?.suspicious ?? 0,
      color: theme.palette.error.light,
    },
    {
      label: 'From Active Users',
      value: data?.purchases[show]?.nonSuspicious ?? 0,
      color: theme.palette.success.light,
    },
  ];
  return hasFinishedLoadingChartsData ? (
    <IndividualChart
      headerTitle="NFT Purchases"
      chartData={purchasesChartData}
    />
  ) : (
    <Loader />
  );
}

function NftTransfersChart({
  data,
  hasFinishedLoadingChartsData,
}: NftActivityChartProps) {
  const theme = useTheme();
  const transfersChartData = (show: AvailableChartValues) => [
    {
      label: 'From/To Blocked/Flagged Users',
      value: data?.transfers[show]?.suspicious ?? 0,
      color: theme.palette.error.light,
    },
    {
      label: 'From/To Active Users',
      value: data?.transfers[show]?.nonSuspicious ?? 0,
      color: theme.palette.success.light,
    },
  ];

  return hasFinishedLoadingChartsData ? (
    <IndividualChart
      chartData={transfersChartData}
      headerTitle="NFT Transfers"
      showChangeOption={false}
    />
  ) : (
    <Loader />
  );
}
