import { CopyAll } from '@mui/icons-material';
import { Card, CardHeader, IconButton, TextField } from '@mui/material';
import { format } from 'date-fns';
import CopyToClipboard from 'react-copy-to-clipboard';
import Flex from 'src/components/shared/Flex';
import Link from 'src/components/shared/Link';
import MagicTable from 'src/components/shared/MagicTable/MagicTable';
import MagicTableNFTCell from 'src/components/shared/MagicTable/NFTCell';
import MagicTableUserCell from 'src/components/shared/MagicTable/UserCell';
import { CommonDateStrings } from 'src/lib/constants/CommonDates';
import useNotify from 'src/lib/hooks/useNotify';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';
import { getTruncatedText } from 'src/lib/utils/formatters';
import Routes from 'src/routes/Routes';
import { UserTransfer } from '../../../types/UserTransfer';
import { FilterFormValues } from './types';

type Props = {
  data: UserTransfer[];
  isLoading: boolean;
  page: number;
  size: number;
  filter: Partial<FilterFormValues>;
  onChangeFilter: (field: keyof FilterFormValues, value: string) => void;
};
export default function UserTransfersView({
  data,
  isLoading,
  page,
  size,
  filter,
  onChangeFilter,
}: Props): JSX.Element {
  const { addParam } = useURLSearchParams();
  const notify = useNotify();

  return (
    <Card>
      <CardHeader
        title="Transfers History"
        action={
          <Flex gap={1} pb={2}>
            <TextField
              label="Starting Date"
              fullWidth
              type="date"
              InputLabelProps={{ shrink: true }}
              defaultValue={filter.startDate}
              inputProps={{
                min: CommonDateStrings.firstTransactionAndPool.date,
                max: CommonDateStrings.today.date,
              }}
              onChange={({ target: { value } }) => {
                onChangeFilter('startDate', value);
              }}
            />
            <TextField
              label="Ending Date"
              fullWidth
              type="date"
              InputLabelProps={{ shrink: true }}
              defaultValue={filter.endDate}
              inputProps={{
                min: CommonDateStrings.firstTransactionAndPool.date,
                max: CommonDateStrings.today.date,
              }}
              onChange={({ target: { value } }) => {
                onChangeFilter('endDate', value);
              }}
            />
          </Flex>
        }
      />
      <MagicTable
        loading={isLoading}
        pageSize={size}
        page={page}
        hideNumberOfRows
        paginationMode="server"
        onPageChange={(newPage) => {
          addParam('page', newPage);
        }}
        onPageSizeChange={(newSize) => {
          addParam('size', newSize);
        }}
        rows={data}
        getRowId={(r) => `${r.poolUuid}${r.nftUuid}`}
        columns={[
          {
            field: 'poolUuid',
            headerName: 'Pool',
            sortable: false,
            flex: 0,
            renderCell: ({ value }) => (
              <Link to={Routes.transactions.pool(value)}>
                {getTruncatedText(value, 4, 3)}
              </Link>
            ),
          },
          {
            field: 'globalWalletFrom',
            headerName: 'From',
            sortable: false,
            renderCell: ({ value }) => {
              if (value?.user) return <MagicTableUserCell user={value.user} />;
              return null;
            },
          },
          {
            field: 'globalWalletTo',
            headerName: 'To',
            sortable: false,
            renderCell: ({ value }) => {
              if (value?.user) return <MagicTableUserCell user={value.user} />;
              if (value?.cryptoWallet)
                return (
                  <>
                    <CopyToClipboard
                      text={value.cryptoWallet.walletAddress}
                      onCopy={() =>
                        notify('Wallet Copied', { variant: 'success' })
                      }
                    >
                      <IconButton>
                        <CopyAll />
                      </IconButton>
                    </CopyToClipboard>
                    {getTruncatedText(value.cryptoWallet.walletAddress, 5, 2)}
                  </>
                );
              return '--';
            },
          },
          {
            field: 'editions',
            headerName: 'Editions',
            align: 'right',
            flex: 0,
            sortable: false,
          },
          {
            field: 'nft',
            headerName: 'NFT',
            sortable: false,
            renderCell: ({ value }) => {
              if (value) return <MagicTableNFTCell nft={value} />;
              return null;
            },
          },

          {
            field: 'createdAt',
            headerName: 'Transfer Date',
            sortable: false,
            align: 'right',
            flex: 1 / 2,
            valueFormatter: ({ value }) => format(value, 'dd/MM/yyyy · HH:mm'),
          },
        ]}
      />
    </Card>
  );
}
