import { ContentCopy, Delete, Edit } from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  InputAdornment,
  MenuItem,
  TextField,
} from '@mui/material';
import { GridSortDirection } from '@mui/x-data-grid';
import { debounce } from 'lodash';
import CopyToClipboard from 'react-copy-to-clipboard';
import { reviewStatusColumn } from 'src/components/modules/Review/reviewStatusColumn';
import Flex from 'src/components/shared/Flex';
import Link from 'src/components/shared/Link';
import MagicTable from 'src/components/shared/MagicTable/MagicTable';
import MoreMenu from 'src/components/shared/MoreMenu';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';
import { AdminListGamesRow } from 'src/lib/types/gameRow';
import { GameVersion } from 'src/lib/types/gameVersion';
import { LabelValue } from 'src/lib/types/labelValue';
import { formatUTCDate } from 'src/lib/utils/formatUTCDate';
import Routes from 'src/routes/Routes';
import Iconify from 'src/template/components/Iconify';
import { FetchGamesQueryParams } from '../../view/List/useView';

type GamesTableProps = {
  data?: AdminListGamesRow[];
  totalCount: number;
  isLoading: boolean;
  params: FetchGamesQueryParams;
  handleOpenUpdateModal: (game: AdminListGamesRow) => void;
  handleOpenDeleteModal: (uuid: string) => void;
  onChangeGameStatus: (
    uuid: string,
    currentStatus: 'active' | 'inactive'
  ) => Promise<void>;
};

export default function GamesTable({
  data,
  isLoading,
  params: { page, size, searchBy, orderBy, sortOrder },
  handleOpenDeleteModal,
  handleOpenUpdateModal,
  totalCount,
}: GamesTableProps) {
  const { addParam, removeParam, allSearchParams } = useURLSearchParams();

  function handleChangeSearch(value: string, param: string = 'query') {
    if (!value) removeParam(param);
    else addParam(param, value);
  }

  // function handleGameStatusChange(
  //   uuid: string,
  //   currentStatus: 'active' | 'inactive'
  // ) {
  //   setRowUuidChangingStatus(uuid);
  //   onChangeGameStatus(uuid, currentStatus).finally(() => {
  //     setRowUuidChangingStatus(undefined);
  //   });
  // }

  const debouncedSearch = debounce(handleChangeSearch, 1000);

  return (
    <MagicTable.Container>
      <Flex gap={2} p={2} justifyContent={'flex-end'}>
        <Autocomplete
          options={SEARCH_OPTIONS}
          fullWidth
          renderInput={(params) => <TextField label="Search By" {...params} />}
          getOptionLabel={(p) => p.label}
          sx={{ maxWidth: 250 }}
          defaultValue={SEARCH_OPTIONS.find((o) => o.value === searchBy)}
          key={searchBy}
          onChange={(_, value) => {
            if (!value) removeParam('searchBy');
            else addParam('searchBy', value.value);
          }}
        />
        <TextField
          fullWidth
          label="Search"
          defaultValue={allSearchParams.query}
          type="search"
          onChange={(e) => {
            const { value } = e.target;
            debouncedSearch(value);
          }}
          key={allSearchParams.query}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Iconify
                  icon="eva:search-fill"
                  sx={{ color: 'text.disabled', width: 20, height: 20 }}
                />
              </InputAdornment>
            ),
          }}
        />
      </Flex>
      <Box px={2}>
        <MagicTable
          rowHeight={64}
          loading={isLoading}
          pageSize={size}
          page={page}
          paginationMode="server"
          onPageChange={(newPage) => {
            addParam('page', newPage);
          }}
          onPageSizeChange={(newSize) => {
            addParam('size', newSize);
          }}
          onSortModelChange={(model) => {
            const currentModel = model[0];
            if (currentModel) {
              addParam('orderBy', currentModel.field);
              if (currentModel.sort) addParam('order', currentModel.sort);
            } else {
              removeParam('order');
              removeParam('orderBy');
            }
          }}
          sortModel={[
            {
              field: orderBy,
              sort: sortOrder as GridSortDirection,
            },
          ]}
          rows={data ?? []}
          rowCount={totalCount}
          columns={[
            //* This collumn is deactivated. To handle game activation/deactivation, update the GameVersion.
            // {
            //   field: 'status',
            //   headerName: 'Status',
            //   align: 'center',
            //   flex: 0,
            //   renderCell({ row }) {
            //     const active = row.status === 'active';
            //     return (
            //       <Stack
            //         alignItems="center"
            //         justifyContent="center"
            //         spacing={0}
            //       >
            //         <Switch
            //           checked={active}
            //           color="success"
            //           onChange={() =>
            //             handleGameStatusChange(
            //               row.uuid,
            //               row.status as 'active' | 'inactive'
            //             )
            //           }
            //           size="small"
            //           disabled={
            //             (isChangingStatus &&
            //               rowUuidChangingStatus === row.uuid) ||
            //             isLoading
            //           }
            //         />
            //         <Box textAlign="center" fontSize={12}>
            //           (
            //           {rowUuidChangingStatus === row.uuid
            //             ? 'Changing...'
            //             : row.status}
            //           )
            //         </Box>
            //       </Stack>
            //     );
            //   },
            // },
            {
              field: 'name',
              headerName: 'Name',
              flex: 1 / 2,
              renderCell({ row }) {
                return (
                  <Link to={Routes.game_center.game.view(row.uuid)}>
                    {row.name}
                  </Link>
                );
              },
            },
            /*
            {
              field: 'modesStrings',
              headerName: 'Game Modes',
              flex: 1,
              sortable: false,
              renderCell({ row }) {
                return row.modesStrings.join(', ') ?? '-';
              },
            },
            {
              field: 'categoriesStrings',
              headerName: 'Categories',
              flex: 1,
              sortable: false,
              renderCell({ row }) {
                return row.categoriesStrings.join(', ') ?? '-';
              },
            },
            {
              field: 'tagsStrings',
              headerName: 'Tags',
              flex: 1,
              sortable: false,
              renderCell({ row }) {
                return row.tagsStrings.join(', ') ?? '-';
              },
            },
            */
            {
              field: 'description',
              headerName: 'Description',
              flex: 1,
            },
            {
              ...reviewStatusColumn,
              renderCell: ({ row }) =>
                row.versions?.[0]
                  ? reviewStatusColumn.renderCell({
                      row: row.versions?.[0] as GameVersion,
                    })
                  : null,
            },
            /*
            {
              field: 'gamePublisherUuid',
              headerName: 'Publisher',
              flex: 1 / 2,
              renderCell({ row }) {
                return row.publisher?.name ?? '-';
              },
              sortable: false,
            },
            */
            {
              field: 'developerName',
              headerName: 'Developer',
              flex: 1 / 2,
              sortable: true,
            },
            {
              field: 'createdAt',
              headerName: 'Created At',
              sortingOrder: ['desc', 'asc'],
              flex: 1 / 2,
              valueFormatter({ value }) {
                return value ? formatUTCDate(value) : '-';
              },
            },
            /* {
              field: 'Contests',
              headerName: '',
              align: 'center',
              sortable: false,
              flex: 0,
              renderCell({ row }) {
                return (
                  <Link to={Routes.game_center.game.contests(row.uuid)}>
                    <Button color="info" variant="contained">
                      Contests
                    </Button>
                  </Link>
                );
              },
            },
            {
              field: 'Rankings',
              headerName: '',
              align: 'center',
              sortable: false,
              flex: 0,
              renderCell({ row }) {
                return (
                  <Link to={Routes.game_center.game.rankings(row.uuid)}>
                    <Button color="warning" variant="contained">
                      Rankings
                    </Button>
                  </Link>
                );
              },
            },
            {
              field: 'Statistic Groups',
              headerName: '',
              align: 'center',
              sortable: false,
              flex: 0,
              renderCell({ row }) {
                return (
                  <Link to={Routes.game_center.game.statistic_groups(row.uuid)}>
                    <Button color="primary" variant="contained">
                      Statistics
                    </Button>
                  </Link>
                );
              },
            }, */
            {
              field: 'actions',
              headerName: 'Actions',
              align: 'center',
              sortable: false,
              flex: 0,
              renderCell({ row }) {
                return (
                  <MoreMenu>
                    <CopyToClipboard text={row.uuid}>
                      <MenuItem aria-label="copy feed uuid">
                        <ContentCopy />
                        Copy UUID
                      </MenuItem>
                    </CopyToClipboard>
                    <MenuItem
                      onClick={() => {
                        handleOpenUpdateModal(row);
                      }}
                    >
                      <Edit />
                      Update
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        handleOpenDeleteModal(row.uuid);
                      }}
                    >
                      <Delete />
                      Delete
                    </MenuItem>
                  </MoreMenu>
                );
              },
            },
          ]}
        />
      </Box>
    </MagicTable.Container>
  );
}

const SEARCH_OPTIONS: LabelValue[] = [
  {
    label: 'Game name',
    value: 'name',
  },
  {
    label: 'Publisher name',
    value: 'gamePublisher.name',
  },
  {
    label: 'Developer name',
    value: 'developerName',
  },
  {
    label: 'Game Mode name',
    value: 'versions.gameMode.name',
  },
  {
    label: 'Category name',
    value: 'categories.name',
  },
  {
    label: 'Tag name',
    value: 'tags.name',
  },
];
