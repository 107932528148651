import { Card, Stack } from '@mui/material';
import PlayerStatsCardInfos from 'src/components/modules/Games/components/playersStatsCardInfos/playersStatsCardInfos';
import GamesSessions from 'src/components/modules/Games/view/ListSessions';
import { PlayerSessionStats } from 'src/lib/types/gameSession';

type Props = {
  data: PlayerSessionStats;
  isLoading: boolean;
};
export default function UserGameSessionsView({
  data,
  isLoading,
}: Props): JSX.Element {
  return (
    <Card>
      <Stack px={2} gap={2}>
        <PlayerStatsCardInfos
          averageSessionDuration={data.averageDuration}
          averageTimeByGame={data.averageTimeByGame}
          negativeRatings={data.negativeRatings}
          positiveRatings={data.positiveRatings}
          sessionsByGame={data.sessionsByGame}
          totalSessionTime={data.totalDuration}
          totalSessions={data.count}
          uniqueGames={data.uniqueGames}
        />
        <GamesSessions showHeader={false} />
      </Stack>
    </Card>
  );
}
