import { dateToDateString, dateToDatetimeString } from '../utils/dateToString';

const today = new Date();
const yesterday = new Date(new Date().setDate(new Date().getDate() - 1));
const tomorrow = new Date(new Date().setDate(new Date().getDate() + 1));
const sevenDaysFromNow = new Date(new Date().setDate(new Date().getDate() + 7));
const sevenDaysAgo = new Date(new Date().setDate(new Date().getDate() - 7));

export const CommonDateStrings = {
  // Constant dates
  firstTransactionAndPool: {
    dateTime: '2023-07-24 18:55:46',
    date: '2023-07-24',
  },
  firstStickyOrderBookMatch: {
    dateTime: '2023-07-24 21:39:51',
    date: '2023-07-24',
  },
  firstSubscription: {
    dateTime: '2023-02-15 18:56:25',
    date: '2023-02-15',
  },
  firstCollection: {
    dateTime: '2019-01-28 16:23:49',
    date: '2019-01-28',
  },
  firstNFT: {
    dateTime: '2018-10-08 10:05:34',
    date: '2018-10-08',
  },
  firstRevenueEventV2CreatedAt: {
    dateTime: '2023-12-22 20:31:17',
    date: '2023-12-22',
  },
  firstRevenueEventV2EventDate: {
    dateTime: '2022-09-01 23:06:27',
    date: '2022-09-01',
  },
  firstPaymentRequest: {
    beforePools: {
      dateTime: '2021-04-15 18:36:55',
      date: '2021-04-15',
    },
    // Add if needed.
    // afterPools: {
    //   dateTime: '',
    //   date: '',
    // },
  },
  firstCoinDelivery: {
    creation: {
      dateTime: '2023-12-22 21:57:03',
      date: '2023-12-22',
    },
    delivery: {
      dateTime: '2023-12-22 21:57:03',
      date: '2023-12-22',
    },
  },
  userSearchRegistrationFeatureLaunchDate: {
    dateTime: '2024-06-04 00:00:00',
    date: '2024-06-04 00:00:00',
  },
  firstNftPackDelivery: {
    creation: {
      dateTime: '2023-10-04 00:00:00',
      date: '2023-10-04',
    },
    delivery: {
      dateTime: '2023-10-05 09:00:00',
      date: '2023-10-05',
    },
  },
  firstReport: {
    dateTime: '2021-05-07 15:34:12',
    date: '2021-05-07',
  },
  firstFeed: {
    dateTime: '2023-02-03 12:44:00',
    date: '2023-02-03',
  },

  // Dynamic dates
  yesterday: {
    dateTime: dateToDatetimeString(yesterday),
    date: dateToDateString(yesterday),
  },
  today: {
    dateTime: dateToDatetimeString(today),
    date: dateToDateString(today),
    endOfDay: dateToDatetimeString(new Date(today.setHours(23, 59, 59, 999))),
  },
  tomorrow: {
    dateTime: dateToDatetimeString(tomorrow),
    date: dateToDateString(tomorrow),
  },
  sevenDaysFromNow: {
    dateTime: dateToDatetimeString(sevenDaysFromNow),
    date: dateToDateString(sevenDaysFromNow),
  },
  sevenDaysAgo: {
    dateTime: dateToDatetimeString(sevenDaysAgo),
    date: dateToDateString(sevenDaysAgo),
    startOfDay: dateToDatetimeString(
      new Date(sevenDaysAgo.setHours(0, 0, 0, 0))
    ),
  },
};
