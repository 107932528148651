import { DeepPartial } from 'react-hook-form';
import { UserChannelStats } from 'src/lib/types/channelWithAdminAnalytics';
import { parseSearchParams } from 'src/lib/utils/parseSearchParams';
import useSWR, { SWRResponse } from 'swr';

export type PossibleParams = {
  startDate: string;
  endDate: string;
};

export const fetchUserChannelStats = (
  uuid: string,
  filter?: DeepPartial<PossibleParams>
): SWRResponse<UserChannelStats> => {
  const parsedParams = parseSearchParams(filter ?? {});
  const response = useSWR<UserChannelStats, Error>(
    `admin/channels/user/${uuid}/stats?${parsedParams}`
  );

  return response;
};
