import { Stack } from '@mui/material';
import EldenTable from 'src/components/shared/EldenTable/EldenTable';
import EldenTablePagination from 'src/components/shared/EldenTable/Plugins/EldenTablePagination';
import EldenTableCell from 'src/components/shared/EldenTable/Widgets/EldenTableCell';
import EldenTableRow from 'src/components/shared/EldenTable/Widgets/EldenTableRow';
import { getSortPropsFromBracketFilterParams } from 'src/components/shared/EldenTable/utils/getSortPropsFromBracketFilterParams';
import { fetchUserMultipleDevices } from 'src/lib/services/users';
import createFilterStore from 'src/lib/stores/createFilterStore';
import Label from 'src/template/components/Label';

type Props = {
  userUuid: string;
};
export function MultipleDevicesTable({ userUuid }: Props): JSX.Element {
  const { filter, setFilter } = useFilter();

  const { data, isLoading } = fetchUserMultipleDevices(userUuid, {
    page: filter.page,
    size: filter.size,
  });

  return (
    <Stack>
      <EldenTable
        footer={
          <EldenTablePagination
            currentPage={filter.page}
            rowsPerPage={filter.size}
            rowsRendered={data?.length ?? 0}
            onChangeRowsPerPage={setFilter}
          />
        }
        data={data}
        {...getSortPropsFromBracketFilterParams(filter, setFilter)}
        loading={isLoading}
      >
        {data?.map((d) => (
          <EldenTableRow key={d.uuid} linkTo={`/device/${d.uuid}`}>
            <EldenTableCell
              id="platformId"
              label="Platform ID"
              sortable={false}
            >
              {d.appleAdvertisingId ?? 'empty'}
            </EldenTableCell>
            <EldenTableCell id="status" label="Status" sortable={false}>
              <Label
                variant="ghost"
                color={d.active ? 'success' : 'error'}
                sx={{ textTransform: 'capitalize' }}
              >
                {d.active ? 'Active' : 'Inactive'}
              </Label>
            </EldenTableCell>
            <EldenTableCell id="uuid" label="Device DB uuid" sortable={false}>
              {d.uuid}
            </EldenTableCell>
            <EldenTableCell id="country" label="Country" sortable={false}>
              {d.country ?? 'empty'}
            </EldenTableCell>
            <EldenTableCell id="platform" label="Platform" sortable={false}>
              {d.platform ?? 'empty'}
            </EldenTableCell>
            <EldenTableCell
              id="description"
              label="description"
              sortable={false}
            >
              {d.description ?? 'empty'}
            </EldenTableCell>
          </EldenTableRow>
        ))}
      </EldenTable>
    </Stack>
  );
}

const useFilter = createFilterStore({
  orderBy: '',
  order: 'desc',
  searchFor: 'uuid',
  query: '',
  page: 0,
  size: 10,
});
