import { GameVersion } from './gameVersion';
import { User } from './users';

export enum ReportStatus {
  UNREAD = 'unread',
  NO_ACTION_NEEDED = 'no_action_needed',
  ACTION_TAKED = 'action_taked',
  IGNORED = 'ignored',
  TAKE_SOME_ACTION = 'take_some_action',
}

export enum ReportPlatform {
  IOS_STICKERS = 'ios-stickers',
  IOS_STICKY_FREE = 'ios-sticky-free',
  ANDROID_STICKERS = 'android-stickers',
  ANDROID_STICKY_FREE = 'android-sticky-free',
  ANDROID_STICKY = 'android-sticky',
  WEB_STICKY = 'web-sticky',
}

export type Report = {
  uuid: string;
  userUuid: string;
  name: string | null;
  email: string | null;
  details: string;
  platform: ReportPlatform;
  version: string;
  status: ReportStatus;
  createdAt: number;
  updatedAt: number;
  nftUuid: string | null;
  collectionUuid: string;
  postId: number;
  clubUuid: string;
  reportedUserUuid: string;
  gameVersionUuid: string;
  roomId: string;
  messageId: string;
  feedItemUuid: string;
  reviewUuid: string;

  user?: User;
  gameVersion?: GameVersion;
};
