import {
  AccessTime,
  AccessTimeFilled,
  AttachMoney,
  CurrencyBitcoin,
  Description,
  Layers,
  Link,
  QrCodeRounded,
  Store,
} from '@mui/icons-material';
import {
  Card,
  CardContent,
  CardHeader,
  Stack,
  SxProps,
  Theme,
  Typography,
} from '@mui/material';
import { capitalCase } from 'change-case';
import { ReactNode } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { BlockchainNetwork } from 'src/lib/constants/blockchain';
import useNotify from 'src/lib/hooks/useNotify';
import { NFT } from 'src/lib/types/nft';
import formatStickyCoinValue from 'src/lib/utils/formatStickyCoinValue';
import { formatDate } from 'src/lib/utils/formatters';
import NFTMetadata from './NFTMetadata';

function isBlockchainOrNA<T>(value: T, nft: NFT): T | string {
  if (nft.blockchain !== BlockchainNetwork.STICKY) return value;
  return 'N/A';
}

type AboutData = {
  label: string;
  value: string | number;
  icon?: ReactNode;
  sx?: SxProps<Theme> | undefined;
  allowCopy?: boolean;
};

interface NFTAboutProps {
  nft: NFT;
}
export default function NFTAbout({ nft }: NFTAboutProps) {
  const notify = useNotify();
  const aboutData: AboutData[] = [
    {
      label: 'UUID',
      value: nft.uuid,
      allowCopy: true,
      icon: <QrCodeRounded />,
      sx: { fontSize: '12px' },
    },
    {
      label: 'File URL',
      value: nft.fileUrl,
      icon: <Link />,
      allowCopy: true,
      sx: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
      },
    },
    {
      label: 'Description',
      value: nft.description,
      icon: <Link />,
      allowCopy: true,
      sx: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
      },
    },
    {
      label: 'Availability From Creator',
      value: `${nft.salesLimit - nft.salesCount}/${nft.salesLimit}`,
      icon: <Layers />,
    },
    {
      label: 'For Sale',
      value: String(nft.buyNow),
      icon: <Store />,
    },
    {
      label: 'Current Floor Price',
      value: formatStickyCoinValue(nft.floorPrice ?? 0),
      icon: <AttachMoney />,
    },
    {
      label: 'Blockchain',
      value: capitalCase(nft.blockchain),
      icon: <CurrencyBitcoin />,
    },
    {
      label: 'Mint Contract',
      value: isBlockchainOrNA(nft.mintContract, nft),
      icon: <Description />,
    },
    {
      label: 'Mint Token',
      value: isBlockchainOrNA(nft.mintToken, nft),
      allowCopy: true,
      icon: <Description />,
    },
    {
      label: 'Mint Token ID',
      value: isBlockchainOrNA(nft.mintTokenId, nft),
      allowCopy: true,
      icon: <Description />,
    },
    {
      label: 'Updated At',
      value: formatDate(nft.updatedAt),
      icon: <AccessTimeFilled />,
    },
    {
      label: 'Created At',
      value: formatDate(nft.createdAt),
      icon: <AccessTime />,
    },
  ];

  return (
    <Card>
      <CardHeader title="Details" />
      <CardContent>
        <Stack spacing={1}>
          {aboutData.map(({ label, value, allowCopy, icon, sx }) => (
            <Stack
              direction="row"
              spacing={2}
              justifyContent="space-between"
              alignItems="center"
              key={label}
            >
              <Stack direction="row" spacing={1} alignItems="center">
                {icon}
                <Typography
                  fontSize="14px"
                  sx={{
                    whiteSpace: 'nowrap',
                  }}
                >
                  {label}:
                </Typography>
              </Stack>
              <CopyToClipboard
                text={allowCopy ? value?.toString() : ''}
                onCopy={() =>
                  allowCopy &&
                  notify('Copied to clipboard', { variant: 'success' })
                }
              >
                <Typography
                  fontSize="14px"
                  fontWeight="bold"
                  textAlign="right"
                  noWrap
                  sx={{
                    cursor: allowCopy ? 'pointer' : 'default',
                    ...sx,
                  }}
                >
                  {value || '-'}
                </Typography>
              </CopyToClipboard>
            </Stack>
          ))}
        </Stack>
        {nft.metadata && <NFTMetadata nft={nft} />}
      </CardContent>
    </Card>
  );
}
