import { Avatar, Stack } from '@mui/material';
import { format } from 'date-fns';
import EldenTable from 'src/components/shared/EldenTable/EldenTable';
import EldenTableCell from 'src/components/shared/EldenTable/Widgets/EldenTableCell';
import EldenTableRow from 'src/components/shared/EldenTable/Widgets/EldenTableRow';
import Link from 'src/components/shared/Link';
import { fetchPossibleMultipleAccounts } from 'src/lib/services/users';
import Routes from 'src/routes/Routes';
import Label from 'src/template/components/Label';

type Props = {
  userUuid: string;
};

export default function PossibleMultipleAccountsTable({
  userUuid,
}: Props): JSX.Element {
  const { data, isLoading } = fetchPossibleMultipleAccounts(userUuid);

  return (
    <Stack>
      <EldenTable data={data} loading={isLoading}>
        {data?.map((d) => (
          <EldenTableRow key={d.uuid} linkTo={`/users/${d.uuid}`}>
            <EldenTableCell
              id="name"
              label="Name"
              align="left"
              sortable={false}
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              <>
                <Avatar
                  alt={d.name ?? undefined}
                  src={d.profileImage ?? undefined}
                  sx={{ mr: 2 }}
                />

                <Link
                  to={Routes.user(d.uuid)}
                  color={d.name ? 'inherit' : 'text.secondary'}
                  underline="hover"
                >
                  {d.name ?? 'empty'}
                </Link>
              </>
            </EldenTableCell>

            <EldenTableCell id="active" label="Active" sortable={false}>
              <Label
                variant="ghost"
                color={d.active ? 'success' : 'error'}
                sx={{ textTransform: 'capitalize' }}
              >
                {d.active ? 'Active' : 'Inactive'}
              </Label>
            </EldenTableCell>
            <EldenTableCell id="email" label="Email" sortable={false}>
              {d.email ?? 'empty'}
            </EldenTableCell>
            <EldenTableCell id="slug" label="Slug" sortable={false}>
              {d.slug ?? 'empty'}
            </EldenTableCell>
            <EldenTableCell id="lastLogin" label="Last Login" sortable={false}>
              {d.lastLogin ? format(d.lastLogin, 'dd/MM/yyyy · HH:mm') : '--'}
            </EldenTableCell>
            <EldenTableCell id="createdAt" label="Created At" sortable={false}>
              {d.createdAt ? format(d.createdAt, 'dd/MM/yyyy · HH:mm') : '--'}
            </EldenTableCell>
          </EldenTableRow>
        ))}
      </EldenTable>
    </Stack>
  );
}
