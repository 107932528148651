import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import PieChart from 'src/components/shared/Charts/PieChart/PieChart';
import CloseableAlert from 'src/components/shared/CloseableAlert';
import Flex from 'src/components/shared/Flex';
import Loader from 'src/components/shared/Loader';
import NumericTypography from 'src/components/shared/NumericTypography';
import fetchUserFollowing from '../../../services/fetchUserFollowing';

type Props = {
  userId: string;
};
export default function ProfileFollowing({ userId }: Props): JSX.Element {
  const theme = useTheme();
  const { data, error, isLoading } = fetchUserFollowing(userId, {
    size: Number.MAX_SAFE_INTEGER,
  });
  const followersStats = data?.data.reduce(
    (acc, curr) => {
      if (curr.active) {
        acc.active += 1;
      } else {
        acc.blocked += 1;
      }
      return acc;
    },
    { blocked: 0, active: 0 }
  );

  const pieChartData = [
    {
      label: 'Blocked/Flagged Users',
      value: followersStats?.blocked ?? 0,
      color: theme.palette.error.light,
    },
    {
      label: 'Non-Blocked/Flagged Users',
      value: followersStats?.active ?? 0,
      color: theme.palette.success.light,
    },
  ];
  return (
    <Card>
      <CardHeader title="Following" />
      <CardContent>
        {isLoading && <Loader />}
        {error && <CloseableAlert severity="error">{error}</CloseableAlert>}
        {data && (
          <>
            <PieChart data={pieChartData} />
            <Stack divider={<Divider />}>
              {pieChartData.map((chartData) => (
                <Flex
                  justifyContent="space-between"
                  py={0.5}
                  key={chartData.label}
                >
                  <Typography>{chartData.label}</Typography>
                  <NumericTypography>{chartData.value}</NumericTypography>
                </Flex>
              ))}
            </Stack>
          </>
        )}
      </CardContent>
    </Card>
  );
}
