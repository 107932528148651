import { OpenInNew } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { GridColumns } from '@mui/x-data-grid';
import { ConsumableListItem } from 'src/components/modules/RevenueEvents/types/Consumable';
import Link from 'src/components/shared/Link';
import MagicTable from 'src/components/shared/MagicTable/MagicTable';
import { PaginatedResponse } from 'src/lib/types/PaginatedResponse';
import Routes from 'src/routes/Routes';
import Label from 'src/template/components/Label';

type Props = {
  data: PaginatedResponse<ConsumableListItem[]>;
  isLoading: boolean;
};
export default function UserConsumablesView({
  data: { meta, body: rows },
  isLoading,
}: Props): JSX.Element {
  return (
    <MagicTable.Container>
      <MagicTable
        loading={isLoading}
        rows={rows ?? []}
        hideFooter
        columns={columns}
      />
    </MagicTable.Container>
  );
}

const columns: GridColumns<ConsumableListItem> = [
  {
    field: 'purchaseDate',
    headerName: 'Purchase Date',
    sortable: false,
    flex: 1,
    renderCell({ row }) {
      return row.purchaseDate;
    },
  },
  {
    field: 'product.name',
    headerName: 'Product',
    renderCell({ row }) {
      return row.product;
    },
  },
  {
    field: 'product.priceUSD',
    headerName: 'Price',
    align: 'right',
    flex: 0,
    renderCell({ row }) {
      return row.price;
    },
  },
  {
    field: 'delivered',
    headerName: 'Delivered',
    flex: 0,
    sortable: false,
    align: 'center',
    renderCell({ value }) {
      return (
        <Label color={value ? 'success' : 'error'}>
          {value ? 'Delivered' : 'Not Delivered'}
        </Label>
      );
    },
  },
  {
    field: 'actions',
    headerName: '',
    sortable: false,
    flex: 0,
    align: 'center',
    renderCell({ row }) {
      return (
        <IconButton
          component={Link}
          to={Routes.consumable(row.originalTransactionId, row.user?.uuid)}
        >
          <OpenInNew />
        </IconButton>
      );
    },
  },
];
