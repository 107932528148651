import { ContentCopy, OpenInNew } from '@mui/icons-material';
import { Card, CardContent, MenuItem, Stack, TextField } from '@mui/material';
import { debounce } from 'lodash';
import CopyToClipboard from 'react-copy-to-clipboard';
import Flex from 'src/components/shared/Flex';
import Link from 'src/components/shared/Link';
import MagicTable from 'src/components/shared/MagicTable/MagicTable';
import MoreMenu from 'src/components/shared/MoreMenu';
import useURLSearchParams from 'src/lib/hooks/useURLSearchParams';
import {
  GameSessionStats,
  GameSessionStatsListItem,
} from 'src/lib/types/gameSession';
import { formatMilliseconds } from 'src/lib/utils/formatMilliseconds';
import Routes from 'src/routes/Routes';
import { FetchGamesStatsQueryParams } from '../../view/ListStats/useView';
import GameStatsCardInfos from '../gamesStatsCardInfos/gamesStatsCardInfos';

type GamesTableProps = {
  data?: GameSessionStatsListItem[];
  isLoading: boolean;
  params: FetchGamesStatsQueryParams;
  generalStatsData?: GameSessionStats;
  totalCount: number;
};

export default function GamesStatsTable({
  data,
  isLoading,
  params: { page, size, startDate, endDate },
  totalCount,
  generalStatsData,
}: GamesTableProps) {
  const { addParam, removeParam } = useURLSearchParams();

  function handleChangeField(value: string, field: string) {
    addParam(field, value);
  }

  const debouncedChangeField = debounce(handleChangeField, 400);

  return (
    <Card>
      <Stack width={'100%'} px={2}>
        <GameStatsCardInfos
          averageSessionDuration={generalStatsData?.averageDuration ?? 0}
          negativeRatings={generalStatsData?.negativeRatings ?? 0}
          positiveRatings={generalStatsData?.positiveRatings ?? 0}
          sessionsByUser={generalStatsData?.sessionByUser ?? 0}
          totalSessionTime={generalStatsData?.totalDuration ?? 0}
          totalSessions={generalStatsData?.count ?? 0}
          uniqueUsers={generalStatsData?.uniqueUsers ?? 0}
          averageTimeByUser={generalStatsData?.averageTimeByUser ?? 0}
          usersByGame={generalStatsData?.usersByGame ?? 0}
        />
        <Flex gap={1} justifyContent={'flex-end'} p={2}>
          <TextField
            type="date"
            label="Start Date"
            InputLabelProps={{ shrink: true }}
            defaultValue={startDate}
            onChange={(e) => {
              const { value } = e.target;
              if (value) {
                debouncedChangeField(value, 'startDate');
              } else removeParam('startDate');
            }}
          />
          <TextField
            type="date"
            label="End Date"
            InputLabelProps={{ shrink: true }}
            defaultValue={endDate}
            onChange={(e) => {
              const { value } = e.target;
              if (value) {
                debouncedChangeField(value, 'endDate');
              } else removeParam('endDate');
            }}
          />
        </Flex>
      </Stack>
      <CardContent>
        <MagicTable
          rows={data ?? []}
          loading={isLoading}
          rowCount={totalCount}
          pageSize={size}
          page={page}
          paginationMode="server"
          onPageChange={(newPage) => {
            addParam('page', newPage);
          }}
          onPageSizeChange={(newSize) => {
            addParam('size', newSize);
          }}
          onSortModelChange={(model) => {
            const currentModel = model[0];
            if (currentModel) {
              addParam('orderBy', currentModel.field);
              if (currentModel.sort) addParam('order', currentModel.sort);
            } else {
              removeParam('order');
              removeParam('orderBy');
            }
          }}
          getRowId={(row) => row.gameUuid}
          columns={[
            {
              field: 'g.name',
              headerName: 'Game',
              flex: 1,
              renderCell({ row }) {
                return (
                  <Link
                    to={`${Routes.game_center.game.view(
                      row.gameUuid
                    )}/?tab=analytics`}
                  >
                    {row.game.name}
                  </Link>
                );
              },
            },
            {
              field: 'uniqueUsers',
              headerName: 'Unique Users',
              flex: 1,
            },
            {
              field: 'count',
              headerName: 'Sessions',
              flex: 1 / 2,
            },
            {
              field: 'sessionByUser',
              headerName: 'Sessions by User',
              valueFormatter({ value }) {
                return (+value).toFixed(2);
              },
              flex: 1,
            },
            {
              field: 'averageTimeByUser',
              headerName: 'Average Time by User',
              valueFormatter({ value }) {
                return formatMilliseconds(value);
              },
              flex: 1,
            },
            {
              field: 'averageDuration',
              headerName: 'Average Session Time',
              valueFormatter({ value }) {
                return formatMilliseconds(value);
              },
              flex: 1,
            },
            {
              field: 'positiveRatings',
              headerName: 'Likes',
              flex: 0,
            },
            {
              field: 'negativeRatings',
              headerName: 'Dislikes',
              flex: 0,
            },
            {
              field: 'actions',
              headerName: 'Actions',
              align: 'center',
              sortable: false,
              flex: 0,
              renderCell({ row }) {
                return (
                  <MoreMenu>
                    <CopyToClipboard text={row.uuid}>
                      <MenuItem aria-label="copy feed uuid">
                        <ContentCopy />
                        Copy UUID
                      </MenuItem>
                    </CopyToClipboard>

                    <Link
                      to={`${Routes.game_center.game.view(
                        row.gameUuid
                      )}/?tab=sessions`}
                    >
                      <MenuItem aria-label="view game">
                        <OpenInNew />
                        View Game Sessions
                      </MenuItem>
                    </Link>
                  </MoreMenu>
                );
              },
            },
          ]}
        />
      </CardContent>
    </Card>
  );
}
