import { Card, CardContent, Stack, Tab, Tabs } from '@mui/material';
import Channels from 'src/components/modules/Channels/view/List';
import Flex from 'src/components/shared/Flex';
import UserChannelInfos from '../../components/UserChannelStatsInfos/playersStatsInfos';
import { UserChannelRelations } from './types';
import useUserChannels from './useView';

const STATUS_TABS = [
  { value: UserChannelRelations.FOLLOWER, label: 'Follower' },
  { value: UserChannelRelations.ADMIN, label: 'Admin' },
  { value: UserChannelRelations.OWNER, label: 'Owner' },
];

export default function UserChannels(): JSX.Element {
  const { userUuid, currentRelation, onChangeRelation, userChannelStats } =
    useUserChannels();

  return (
    <Card>
      <Flex sx={{ px: 2, bgcolor: 'background.neutral' }}>
        <Tabs
          allowScrollButtonsMobile
          variant="scrollable"
          scrollButtons="auto"
          value={currentRelation}
          onChange={onChangeRelation}
        >
          {STATUS_TABS.map((tab) => (
            <Tab
              disableRipple
              key={tab.value}
              label={tab.label}
              value={tab.value}
            />
          ))}
        </Tabs>
      </Flex>
      <CardContent>
        <Stack gap={2}>
          {currentRelation == UserChannelRelations.FOLLOWER && (
            <UserChannelInfos
              clicks={userChannelStats?.clicks ?? 0}
              views={userChannelStats?.views ?? 0}
              watchedTime={userChannelStats?.watchedTime ?? 0}
            />
          )}
          <Channels
            showHeader={false}
            relation={currentRelation as UserChannelRelations}
            userUuid={userUuid}
          />
        </Stack>
      </CardContent>
    </Card>
  );
}
