import React, { ReactElement } from 'react';
import Iconify, { IconifyProps } from 'src/template/components/Iconify';
import { v4 } from 'uuid';

const MainInfo = React.lazy(
  () => import('src/components/modules/Clubs/view/MainInfo')
);
const Members = React.lazy(
  () => import('src/components/modules/Clubs/view/ListMembers')
);
const Bans = React.lazy(
  () => import('src/components/modules/Clubs/view/ListBansHistory')
);

export enum ClubTabs {
  MAIN = 'main_info',
  MEMBERS = 'members',
  BANS = 'bans_history',
}

interface IClubTabs {
  value: ClubTabs;
  icon: ReactElement<IconifyProps>;
  component: () => JSX.Element;
}

const CLUB_TABS: IClubTabs[] = [
  {
    value: ClubTabs.MAIN,
    icon: (
      <Iconify icon="material-symbols:info-outline" width={20} height={20} />
    ),
    component: () => <MainInfo />,
  },
  {
    value: ClubTabs.MEMBERS,
    icon: (
      <Iconify icon="material-symbols:person-outline" width={20} height={20} />
    ),
    component: () => <Members key={v4()} />,
  },
  {
    value: ClubTabs.BANS,
    icon: (
      <Iconify
        icon="material-symbols:person-off-outline"
        width={20}
        height={20}
      />
    ),
    component: () => <Bans key={v4()} />,
  },
];

export default CLUB_TABS;
