import { GridSortDirection } from '@mui/x-data-grid';
import UserPurchasesView from './View';
import useUserPurchasesView from './useView';

export default function UserPurchases(): JSX.Element {
  const {
    purchasesData,
    purchasesIsLoading,
    sellersData,
    sellersIsLoading,
    page,
    size,
    order,
    sortBy,
    section,
    filter,
    onChangeFilter,
  } = useUserPurchasesView();
  return (
    <UserPurchasesView
      purchasesData={purchasesData ?? []}
      purchasesIsLoading={purchasesIsLoading}
      sellersData={sellersData ?? []}
      sellersIsLoading={sellersIsLoading}
      page={page}
      size={size}
      order={order as GridSortDirection}
      sortBy={sortBy!}
      section={section}
      filter={filter}
      onChangeFilter={onChangeFilter}
    />
  );
}
