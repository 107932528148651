import { Button, Divider, Stack } from '@mui/material';
import { useState } from 'react';
import Loader from 'src/components/shared/Loader';
import NumberInfo from 'src/components/shared/NumberInfo/NumberInfo';
import { fetchUserBehavior } from 'src/lib/services/users';
import InteractedUsersTable from './DetailTables/InteractedUsersTable';
import { MultipleDevicesTable } from './DetailTables/MultipleDevicesTable';
import PossibleMultipleAccountsTable from './DetailTables/PossibleMultipleAccountsTable';
import { ProfileBehaviorViews } from './types';

export type Props = {
  userUuid: string;
};
export default function UserBehaviorSection({ userUuid }: Props) {
  const { data: userBehavior, isLoading: isLoadingUserBehavior } =
    fetchUserBehavior(userUuid);
  const [profileBehaviorView, setProfileBehaviorView] =
    useState<ProfileBehaviorViews>(null);

  if (isLoadingUserBehavior || !userBehavior) return <Loader />;
  return (
    <>
      <Stack
        direction="row"
        spacing={3}
        alignItems="center"
        justifyContent="center"
      >
        <NumberInfo
          title="Distinct Users Interacted With"
          total={userBehavior.distinctUsersInteractedWith}
          componentProps={{
            onClick: () => setProfileBehaviorView('interacted'),
          }}
        />
        <NumberInfo
          title="Possible Multiple Accounts"
          total={userBehavior.possibleMultipleAccounts}
          componentProps={{
            onClick: () => setProfileBehaviorView('accounts'),
          }}
        />
        <NumberInfo
          title="Devices Associated"
          total={userBehavior.devicesAssociated}
          componentProps={{
            onClick: () => setProfileBehaviorView('devices'),
          }}
        />
      </Stack>
      {profileBehaviorView !== null ? (
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          spacing={1}
          px={4}
        >
          <Divider sx={{ flex: 1, alignItems: 'center' }} />
          <Button size="small" onClick={() => setProfileBehaviorView(null)}>
            Hide Details
          </Button>
          <Divider sx={{ flex: 1 }} />
        </Stack>
      ) : null}
      {profileBehaviorView === 'interacted' ? (
        <InteractedUsersTable userUuid={userUuid} />
      ) : profileBehaviorView === 'accounts' ? (
        <PossibleMultipleAccountsTable userUuid={userUuid} />
      ) : profileBehaviorView === 'devices' ? (
        <MultipleDevicesTable userUuid={userUuid} />
      ) : null}
    </>
  );
}
