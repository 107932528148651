import { parseSearchParams } from 'src/lib/utils/parseSearchParams';
import useSWR, { SWRConfiguration, SWRResponse } from 'swr';
import { UserTransfer } from '../types/UserTransfer';

export default function fetchUserTransfers(
  userId: string,
  params?: Partial<PossibleParams>,
  options?: SWRConfiguration
): SWRResponse<UserTransfer[]> {
  const parsedParams = parseSearchParams(params ?? {});
  const { data, ...response } = useSWR(
    `admin/users/${userId}/transfers?${parsedParams}`,
    options
  );

  return { ...response, data };
}

type PossibleParams = {
  page: number | string;
  size: number | string;
  filter: {
    createdAt: {
      gte: string;
      lte: string;
    };
  };
};
